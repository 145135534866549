<!-- @format -->

<template>
  <div class="form-div">
    <alert-box
      :alertText="errMsg"
      :alertType="errType"
      v-if="alert"
    ></alert-box>
    <div class="title-bar">
      <div class="title-text">App Users</div>
      <div class="close-btn">
        <router-link to="/dashboard">
          <span class="fas fa-times-circle"></span
        ></router-link>
      </div>
    </div>
    <div class="content-area">
      <div class="filter-area">
        <div class="row">
          <div class="col-sm-2">
            <label for="class">Class : </label>
            <select
              name="cldes"
              class="form-select"
              v-model="Cmbcldes"
              tabindex="2"
              ref="class"
              @blur="getSections"
            >
              <option :value="{ id: '', text: '', text1: '' }">
                --Select--
              </option>
              <option
                v-for="item in Classes"
                :key="item.class"
                :value="{
                  id: item.class,
                  text: item.cldes,
                  text1: item.class1,
                }"
              >
                {{ item.cldes }}
              </option>
            </select>
          </div>
          <div class="col-sm-2">
            <label for="sec">Section :</label>
            <select
              name="sec"
              class="form-select"
              v-model="sec"
              tabindex="2"
              ref="sec"
            >
              <option :value="''">--All--</option>
              <option
                v-for="item in Sections"
                :key="item.sec"
                :value="item.sec"
              >
                {{ item.sec }}
              </option>
            </select>
          </div>
          <div class="col-sm-2">
            <div class="appfilter">
              <input
                type="radio"
                name="appfilter"
                ref="appfilter"
                id="appfilter"
                v-model="rdb"
                value="1"
                @change="getRec"
                checked
              />&nbsp;
              <label for="appfilter">App Users</label>
            </div>
            <div class="appfilter">
              <input
                type="radio"
                name="appfilter"
                ref="appfilter1"
                id="appfilter1"
                v-model="rdb"
                value="2"
                @change="getRec"
              />&nbsp;
              <label for="appfilter1">Not App Users</label>
            </div>
          </div>
        </div>

        <div class="filterbtn-div">
          <button type="button" class="btn btn-primary" @click="getRec">
            Filter
          </button>
          <button type="button" class="btn btn-info" @click="clearFilter">
            Clear Filter
          </button>
          <button type="button" class="btn btn-success" @click="exportRep">
            Export
          </button>
        </div>
        <div class="exportlink">
          <a :href="fileLink">{{ fileName }}</a>
        </div>
        <div>
          <input
            type="text"
            class="form-control searchbox"
            placeholder="Search"
            v-model="search"
            @keyup="getRec"
            ref="search"
            autofocus
          />
        </div>
      </div>

      <div class="grid-area">
        <card-loader v-if="loading"></card-loader>
        <table border="1" v-else>
          <thead>
            <tr>
              <th class="srnocol">#</th>
              <th>Status</th>
              <th>Student Code</th>
              <th>Student Name</th>
              <th>Class &nbsp;&nbsp;Sec</th>
              <th>Father Name</th>
              <th v-if="rdb == 1">User Name</th>
              <th v-if="rdb == 1">Password</th>
              <th v-if="rdb == 1"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in mstList" :key="row.trackno">
              <td>{{ row.srno }}</td>
              <td>
                {{ row.activeflg == "Y" ? "Active" : "Non-Active" }}
                <span class="inactivedtm" v-if="row.deldtm != ''">{{
                  row.deldtm
                }}</span>
              </td>
              <td>{{ row.glc }}</td>
              <td>{{ row.gln }}</td>
              <td>{{ row.cldes }} &nbsp;&nbsp;{{ row.sec }}</td>
              <td>{{ row.fname }}</td>
              <td v-if="rdb == 1">{{ row.uname }}</td>
              <td v-if="row.showpass != 0">{{ row.upass }}</td>
              <td v-else>*********</td>
              <td v-if="rdb == 1">
                <button
                  class="btn btn-primary passbtn"
                  type="button"
                  @click="showpass(row.trackno)"
                >
                  {{ row.buttonText }}
                </button>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="10"></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import AlertBox from "@/components/AlertBox.vue";
import CardLoader from "@/components/CardLoader.vue";
import axios from "axios";
// import MyFun from "@/js/MyFun";

export default {
  components: {
    AlertBox,
    CardLoader,
  },
  data() {
    return {
      formId: "001",
      permission: [],
      optn: "StudentList",
      search: "",
      ufilter: "",
      mstList: [],
      userId: this.$store.state.userId,
      userName: this.$store.state.userName,
      errMsg: "",
      errType: "",
      alert: false,
      loading: false,
      fileName: "",
      fileLink: "",
      class: "",
      cldes: "",
      class1: "",
      sec: "",
      Classes: "",
      Sections: "",
      Cmbcldes: { id: "", text: "", text1: "" },
      rdb: "1",
    };
  },
  methods: {
    async getRec() {
      this.loading = true;
      console.log(this.rdb);
      await axios
        .post("users.php", {
          optn: "StudentList",
          msearch: this.search,
          userid: this.userId,
          username: this.userName,
          mclass: this.class,
          mclass1: this.class1,
          msec: this.sec,
          mrdb: this.rdb,
        })
        .then((response) => {
          console.log(response);
          var resp = response.data;
          if (resp.Errorsts == false) {
            this.mstList = resp.List;
          } else {
            console.log("Error:" + this.ErroMsg);
          }
        });
      this.loading = false;
    },
    showpass(xtrackno) {
      var gidx = this.mstList.findIndex((row) => {
        return row.trackno == xtrackno;
      });

      if (this.mstList[gidx].showpass == 1) {
        this.mstList[gidx].showpass = 0;
        this.mstList[gidx].buttonText = "Show Password";
      } else {
        this.mstList[gidx].showpass = 1;
        this.mstList[gidx].buttonText = "Hide Password";
      }
    },
    async clearFilter() {
      this.class = "";
      this.class1 = "";
      this.sec = "";
      this.Cmbcldes = { id: "", text: "", text1: "" };
      this.getRec();
      this.fileName = "";
      this.fileLink = "";
    },
    async exportRep() {
      await axios
        .post("users.php", {
          optn: "StudentRep",
          msearch: this.search,
          userid: this.userId,
          username: this.userName,
          mclass: this.class,
          mclass1: this.class1,
          mrdb: this.rdb,
          msec: this.sec,
        })
        .then((response) => {
          var resp = response.data;
          if (resp.Errorsts == false) {
            this.fileName = resp.FileName;
            this.fileLink = resp.FileLink;
          } else {
            console.log("Error:" + this.ErroMsg);
          }
        });
    },
    async getclmst() {
      await axios
        .post("messages.php", {
          optn: "Classes",
          userid: this.userId,
          isteacher: this.$store.state.isTeacher,
        })
        .then((response) => {
          var resp = response.data;
          if (resp.Errorsts == false) {
            this.Classes = resp.List;
          } else {
            console.log("Error:" + resp.ErrorMsg);
          }
        });
    },
    async getSections() {
      this.class = this.Cmbcldes.id;
      this.cldes = this.Cmbcldes.text;
      this.class1 = this.Cmbcldes.text1;
      await axios
        .post("messages.php", {
          optn: "Classec",
          class: this.class,
          cldes: this.cldes,
          class1: this.class1,
        })
        .then((response) => {
          var resp = response.data;
          if (resp.Errorsts == false) {
            this.Sections = resp.List;
            this.sec = "";
          } else {
            console.log("Error:" + resp.ErrorMsg);
          }
        });
    },
    showAlert(xtime = 1500) {
      this.alert = true;
      setTimeout(() => {
        this.alert = false;
      }, xtime);
    },
  },
  async mounted() {
    await this.getRec();
    await this.getclmst();
    await this.getSections();
  },
};
</script>
<style scoped>
@import "./css/masterlist.css";
</style>
<style scoped>
.filterbtn-div {
  position: relative;
  width: 100%;
  display: block;
  padding: 5px;
  text-align: left;
}
.filterbtn-div .btn {
  position: relative;
  margin: 10px;
  margin-left: 0;
}
.exportlink {
  display: block;
  text-align: left;
}
.appfilter {
  display: block;
  margin-bottom: 5px;
}
.appfilter label {
  cursor: pointer;
}

.inactivedtm {
  position: relative;
  display: block;
  font-size: xx-small;
  font-weight: bold;
}
</style>
