/** @format */

import { createStore } from "vuex";

export default createStore({
  state: {
    isAuth: localStorage.getItem("Auth"),
    userName: localStorage.getItem("Uname"),
    userId: localStorage.getItem("Uid"),
    schoolName: localStorage.getItem("school"),
    showDash: JSON.parse(localStorage.getItem("ShowDash")),
    isTeacher: JSON.parse(localStorage.getItem("IsTeacher")),
    // filePath: "https://gt.holyheartamritsar.in/admin/php/",
    // filePath: "https://test.holyheartamritsar.in/admin/php/",
    filePath: "https://holyheartamritsar.in/admin/php/",
    // filePath: "http://192.168.29.51:8180/HolyHeart/presi/admin/src/php/",
  },
  mutations: {},
  actions: {},
  modules: {},
});
