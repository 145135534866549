/** @format */

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import MandatoryPage from "./components/MandatoryPage.vue";
import AlertBox from "./components/AlertBox.vue";
import CardLoader from "./components/CardLoader.vue";
// axios.defaults.baseURL = "http://192.168.29.178:8880/VUE/holy/src/php/";///Test Link
// axios.defaults.baseURL = "http://192.168.29.51:8180/HolyHeart/presi/admin/src/php/";///Test Link

// axios.defaults.baseURL = "https://test.holyheartamritsar.in/admin/php"; ///Main Link
// axios.defaults.baseURL = "https://gt.holyheartamritsar.in/admin/php"; ///Main Link
axios.defaults.baseURL = "https://holyheartamritsar.in/admin/php"; ///Main Link

// createApp(App).use(store).use(router).mount("#app");


const app = createApp(App).use(router);
app.use(store);
app.use(router);
app.component("MandatoryPage", MandatoryPage);
app.component("AlertBox", AlertBox);
app.component("CardLoader", CardLoader);
app.mount("#app");
