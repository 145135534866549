<!-- @format -->

<template>
  <div class="form-div">
    <alert-box
      :alertText="errMsg"
      :alertType="errType"
      v-if="alert"
    ></alert-box>
    <teleport to="#app">
      <confirm-box ref="confirm"></confirm-box>
      <popup-loader
        :LoaderText="'Deleting Please wait..'"
        v-if="isdeleting == true"
      ></popup-loader>
    </teleport>
    <div class="title-bar">
      <div class="title-text">Homework List</div>
      <div class="close-btn">
        <router-link to="/dashboard">
          <span class="fas fa-times-circle"></span
        ></router-link>
      </div>
    </div>
    <div class="content-area">
      <div class="filter-area">
        <div class="dt-filter">
          <label> From Date:</label>
          <input type="date" class="form-control" v-model="mdate1" />
          <label> To Date:</label>
          <input type="date" class="form-control" v-model="mdate2" />
          <label>User/Teacher:</label>
          <select v-model="ecode" class="form-select">
            <option value="">--Select--</option>
            <option v-for="rw in userlist" :key="rw.uid" :value="rw.uid">
              {{ rw.ename }}
            </option>
          </select>
        </div>
        <div class="user-filter"></div>
        <div class="row class-filter">
          <div class="col-sm-2">
            <label for="class">Class : </label>
            <select
              name="cldes"
              class="form-select"
              v-model="cmbclass"
              tabindex="2"
              ref="class"
              @blur="getSections"
            >
              <option :value="''">--Select--</option>
              <option v-for="item in Classes" :key="item.class" :value="item">
                {{ item.cldes }}
              </option>
            </select>
          </div>
          <div class="col-sm-2">
            <label for="sec">Section :</label>
            <select
              name="sec"
              class="form-select"
              v-model="cmbsec"
              tabindex="2"
              ref="sec"
            >
              <option :value="''">--All--</option>
              <option
                v-for="item in Sections"
                :key="item.sec"
                :value="item.sec"
              >
                {{ item.sec }}
              </option>
            </select>
          </div>
        </div>

        <div class="filter-btns">
          <button type="button" class="btn btn-primary" @click="filterRec">
            Filter
          </button>
          &nbsp;&nbsp;&nbsp;
          <button type="button" class="btn btn-primary" @click="clearFilter">
            Clear Filter
          </button>
        </div>
        <input
          type="text"
          class="form-control searchbox"
          placeholder="Search"
          v-model="search"
          ref="search"
          autofocus
        />
      </div>
      <div class="grid-area">
        <card-loader v-if="loading"></card-loader>
        <div
          v-for="(row, index) in mstList"
          :key="row.trackno"
          class="message-div"
        >
          <span class="srno">{{ index + 1 }}.</span>
          <span class="subject">{{ row.msubject }}</span>
          <span class="grade">{{ row.cldes + "  " + row.sec }}</span>
          <span class="message">{{ row.msg }}</span>
          <div class="sender-detail">
            <span class="sendedby">Sent By: {{ row.createdby }}</span>
            <span class="senton">{{ row.created_dt }}</span>
          </div>
          <div class="icon-div">
            <span
              class="fas fa-trash"
              @click="delRec(row)"
              v-if="permission.delete"
            >
            </span>
            <span
              class="fa fa-paperclip"
              aria-hidden="true"
              @click="toggleAttachment(row)"
            >
            </span>
            {{ row.filecnt == null ? 0 : row.filecnt }}
            <i class="fa fa-eye" @click="toggleStuList(row)"></i>
            {{ row.seencnt == null ? 0 : row.seencnt }}
          </div>
        </div>
        <read-list
          ref="readlist"
          v-show="showread == true"
          @hidebox="toggleStuList('')"
        ></read-list>

        <attachment-list
          ref="attachment"
          v-show="showattachment == true"
          @hidebox="toggleAttachment('')"
        ></attachment-list>
      </div>
    </div>
  </div>
</template>

<script>
import AlertBox from "@/components/AlertBox.vue";
import CardLoader from "@/components/CardLoader.vue";
import ReadList from "../../components/ReadList.vue";
import AttachmentList from "@/components/AttachmentList.vue";
import ConfirmBox from "@/components/ConfirmBox.vue";
import PopupLoader from "../../components/PopupLoader.vue";
import axios from "axios";
import MyFun from "@/js/MyFun";

export default {
  components: {
    AlertBox,
    CardLoader,
    ReadList,
    AttachmentList,
    ConfirmBox,
    PopupLoader,
  },
  data() {
    return {
      formId: "002",
      permission: [],
      Classes: [],
      Sections: [],
      cmbclass: "",
      cmbsec: "",
      addForm: false,
      optn: "Add",
      search: "",
      mstList: [],
      userId: this.$store.state.userId,
      userName: this.$store.state.userName,
      errMsg: "",
      errType: "",
      alert: false,
      loading: false,
      fileName: "",
      fileLink: "",
      showread: false,
      showattachment: false,
      isdeleting: false,
      userlist: [],
      ecode: "",
      mdate1: MyFun.getdtnow(),
      mdate2: MyFun.getdtnow(),
      class: "",
      class1: "",
      cldes: "",
      sec: "",
    };
  },
  methods: {
    addRec() {
      this.optn = "Add";
      this.addForm = true;
      this.$refs.vendorForm.formBlank();
    },
    toggleStuList(xrow) {
      this.showread = !this.showread;
      if (this.showread == true) {
        this.getReadCount(xrow);
      }
    },
    toggleAttachment(xrow) {
      this.showattachment = !this.showattachment;
      if (this.showattachment == true) {
        this.getAttachments(xrow);
      }
    },
    async delRec(xobj) {
      var yn = await this.$refs.confirm.show({
        title: "Alert",
        message:
          "<h3>Want to Delete?</h3>" +
          "<small><i><b>Note:</b> It will delete message & attachment from all related Classes and Sections. </i></small>",
        okButton: "Yes,Delete it",
        msgboxType: "question",
        cancelButton: "No,Cancel",
      });
      if (yn == true) {
        this.isdeleting = true;
        await axios
          .post("homework.php", {
            optn: "Delete",
            docno: xobj.docno,
            userid: this.userId,
          })
          .then((response) => {
            this.isdeleting = false;
            var resp = response.data;
            if (resp.Errorsts == true) {
              console.log(resp.ErrorMsg);
            } else {
              if (resp.RowCount > 0) {
                this.errType = "danger";
                this.errMsg = resp.ErrorMsg;
                this.showAlert(3000);
              } else {
                this.filterRec();
              }
            }
          });
      }
    },
    async filterRec() {
      this.loading = true;
      await axios
        .post("homework.php", {
          optn: "List",
          msearch: this.search,
          userid: this.userId,
          username: this.userName,
          isteacher: this.$store.state.isTeacher,
          mdate1: this.mdate1,
          mdate2: this.mdate2,
          ecode: this.ecode,
          class: this.class,
          class1: this.class1,
          sec: this.cmbsec,
        })
        .then((response) => {
          var resp = response.data;
          if (resp.Errorsts == false) {
            this.mstList = resp.List;
          } else {
            console.log("Error:" + this.ErroMsg);
          }
        });
      this.loading = false;
    },
    clearFilter() {
      this.mdate1 = MyFun.getdtnow();
      this.mdate1 = MyFun.getdtnow();
      this.ecode = "";
      this.cmbclass = "";
      this.cmbsec = "";
      this.class = "";
      this.cldes = "";
      this.class1 = "";
      this.filterRec();
    },
    showAlert(xtime = 1500) {
      this.alert = true;
      setTimeout(() => {
        this.alert = false;
      }, xtime);
    },
    async getUsers() {
      await axios
        .post("users.php", {
          optn: "List",
          msearch: "",
        })
        .then((response) => {
          var resp = response.data;
          if (resp.Errorsts == false) {
            this.userlist = resp.List;
          } else {
            console.log("Error:" + this.ErroMsg);
          }
        });
    },
    async getClasses() {
      await axios
        .post("classes.php", {
          optn: "Classes",
          userid: this.userId,
          isteacher: this.$store.state.isTeacher,
        })
        .then((response) => {
          var resp = response.data;
          if (resp.Errorsts == false) {
            this.Classes = resp.List;
          } else {
            console.log("Error:" + resp.ErrorMsg);
          }
        });
    },
    async getSections() {
      if (this.cmbclass == "") {
        this.class = "";
        this.cldes = "";
        this.class1 = "";
      } else {
        this.class = this.cmbclass.class;
        this.cldes = this.cmbclass.cldes;
        this.class1 = this.cmbclass.class1;
        await axios
          .post("classes.php", {
            optn: "Classec",
            class: this.class,
            cldes: this.cldes,
            class1: this.class1,
          })
          .then((response) => {
            var resp = response.data;
            if (resp.Errorsts == false) {
              this.Sections = resp.List;
              this.sec = "";
            } else {
              console.log("Error:" + resp.ErrorMsg);
            }
          });
      }
    },
    async getAttachments(xrw) {
      var des = xrw.cldes + " " + xrw.sec;
      var ListData = [];
      await axios
        .post("homework.php", {
          optn: "Attachment",
          docno: xrw.docno,
        })
        .then((resp) => {
          console.log(resp);

          if (resp.data.Errorsts == false) {
            ListData = resp.data.List;
          } else {
            console.log("Error");
            console.log(resp);
          }
        });
      var xrow = { des: des, list: ListData };
      this.$refs.attachment.getReaddata(xrow);
    },
    async getReadCount(xrw) {
      var des = xrw.cldes + " " + xrw.sec;
      var ListData = [];
      await axios
        .post("homework.php", {
          optn: "ReadCount",
          docno: xrw.docno,
          class: xrw.class,
          class1: xrw.class1,
          sec: xrw.sec,
        })
        .then((resp) => {
          if (resp.data.Errorsts == false) {
            ListData = resp.data.List;
          } else {
            console.log("Error");
            console.log(resp);
          }
        });
      var xrow = { des: des, list: ListData };
      this.$refs.readlist.getReaddata(xrow);
    },
  },
  async mounted() {
    this.permission = await MyFun.getPermissions(this.userId, this.formId);
    await this.getUsers();
    await this.getClasses();
    await this.clearFilter();
  },
};
</script>

<style scoped>
.message-div {
  position: relative;
  display: block;
  width: calc(100% - 10px);
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
  border: 1px solid lightgray;
}
.message-div .subject {
  position: relative;
  font-weight: bold;
}

.message-div .srno {
  position: relative;
  margin-left: 2px;
  margin-right: 2px;
}
.message-div .grade {
  position: relative;
  display: block;
  font-weight: 600;
  font-size: 14px;
  margin-left: 18px;
}
.message-div .message {
  position: relative;
  display: block;
  margin-left: 18px;
  line-height: 18px;
}
.message-div .sender-detail {
  position: relative;
  display: block;
}
.message-div .sender-detail .sendedby {
  position: relative;
  display: block;
  font-size: 12px;
  text-align: right;
  font-weight: bolder;
  margin-top: 10px;
}
.message-div .sender-detail .senton {
  position: relative;
  display: block;
  font-size: 12px;
  text-align: right;
  font-weight: bolder;
}

.message-div .icon-div {
  position: relative;
  display: block;
  width: 100%;
}
.message-div .icon-div .fa-trash {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.message-div .icon-div .fa-paperclip {
  position: relative;
  display: inline-block;
  font-size: 18px;
  margin-left: 8px;
  top: 2px;
  cursor: pointer;
}

.message-div .icon-div .fa-paperclip:hover {
  position: relative;
  color: gray;
}
.message-div .icon-div .fa-eye {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-left: 8px;
}
.message-div .icon-div .fa-eye:hover {
  color: rgb(94, 94, 243);
}

.form-div {
  position: relative;
  margin: 5px;
  padding: 0px;
  width: calc(100% - 10px);
  border-radius: 5px;
  background-color: white;
  border: 1px solid lightgray;
  top: 10px;
}

.title-bar {
  position: relative;
  display: block;
  padding: 10px;
  width: 100%;
  background-color: #242424;
  color: whitesmoke;
  font-weight: bold;
  text-align: left;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.title-bar div {
  display: inline-block;
}
.close-btn {
  position: relative;
  justify-content: right;
  right: 0px;
  float: right;
  cursor: pointer;
}
.close-btn a {
  position: relative;
  color: white;
  font-size: 20px;
  top: -3px;
}
.close-btn a:hover {
  color: red;
}
.content-area {
  padding: 15px;
}

.btn-area {
  position: relative;
  display: block;
  padding: 5px;
}
.btn-area .exportbtn {
  position: relative;
  float: right;
}

.filter-area {
  padding: 5px;
}

.grid-area {
  padding: 2px;

  width: 100%;
  background-color: white;
  max-height: calc(100vh - (250px));
  overflow: auto;
}
.grid-area table {
  width: 100%;
}
.grid-area table thead {
  position: sticky;
  top: 0px;
  background-color: #242424;
  color: white;
}
.grid-area table tfoot {
  position: sticky;
  bottom: 0px;
  background-color: #242424;
  color: white;
}

.grid-area table thead tr th {
  font-weight: 400;
  padding: 5px;
}

.grid-area table tbody tr td {
  cursor: pointer;
  padding: 5px;
  border-bottom: 0.5px solid lightgray;
}
.grid-area table tbody tr:hover {
  background-color: lightgray;
}

.editcol {
  width: 80px;
}
.srnocol {
  width: 60px;
}

.smalldt {
  position: relative;
  display: block;
  font-size: xx-small;
  font-weight: bold;
}

.dt-filter {
  position: relative;
  display: block;
  font-size: 11px;
  font-weight: bold;
  margin: 0px;
}
.dt-filter label {
  position: relative;
  display: inline-block;
  margin-top: 5px;
  width: 80px;
  left: 4px;
}
.dt-filter .form-control {
  position: relative;
  font-size: 11px;
  font-weight: bold;
  width: 150px;
  display: inline-block;
  margin-bottom: 5px;
}

.dt-filter .form-select {
  position: relative;
  font-size: 11px;
  font-weight: bold;
  width: 250px;
  display: inline-block;
  margin-bottom: 5px;
}

.class-filter {
  margin-top: 0px;
}
.class-filter .col-sm-2 {
  margin-top: 0px;
}
.class-filter .form-select {
  position: relative;
  font-size: 11px;
  margin-bottom: 5px;
}

.class-filter label {
  position: relative;
  font-size: 11px;
  font-weight: bold;
}
.user-filter {
  position: relative;
  display: block;
  font-size: 11px;
  font-weight: bold;
  margin: 0px;
}

.user-filter label {
  position: relative;
  display: inline-block;
  margin-top: 5px;
  width: 80px;
}

.user-filter .form-control {
  position: relative;
  font-size: 11px;
  font-weight: bold;
  display: inline-block;
  margin-bottom: 5px;
}

.filter-btns {
  position: relative;
  display: block;
  margin-bottom: 8px;
}

@media only screen and (max-width: 600px) {
  .grid-area {
    margin-top: 15px;
  }
  .grid-area table thead tr th,
  .grid-area table tbody tr td,
  .grid-area table tfoot tr td {
    padding: 2px;
    font-size: 12px;
  }

  .dt-filter label {
    position: relative;
    display: block;
    width: 80px;
  }
  .dt-filter .form-control {
    position: relative;
    display: block;
    font-size: 11px;
    font-weight: bold;
    width: 100%;
    display: inline-block;
  }

  .dt-filter .form-select {
    position: relative;
    font-size: 11px;
    font-weight: bold;
    width: 100%;
    display: inline-block;
    margin-bottom: 5px;
  }
}
</style>
