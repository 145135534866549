<template>
  <div class="ChartCanvas">
    <canvas :id="ChartId" class="canvascenter"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
export default {
  name: "BarChart",
  props: [
    "ChartId",
    "ChartLabel",
    "ChartData",
    "ChartColor",
    "ChartHead",
    "LegendDisplay",
  ],
  data() {
    return {
      barChartData: {
        type: "bar",
        data: {
          labels: this.ChartLabel,
          datasets: [
            {
              label: this.ChartHead,
              backgroundColor: this.ChartColor,
              data: this.ChartData,
              barThickness: 20,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          responsive: false,
          plugins: {
            title: {
              display: true,
              text: "",
            },
            legend: {
              display: this.LegendDisplay,
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
            },
            y: {
              grid: {
                display: false,
              },
            },
          },
        },
      },
    };
  },
  methods: {
    refreshChart() {
      const ctx = document.getElementById(this.ChartId);
      ctx.height = 250;
      ctx.width = 350;
      new Chart(ctx, this.barChartData);
    },
  },
};
</script>
<style scoped>
.ChartCanvas {
  text-align: center;
}
.canvascenter {
  margin: 0 auto;
}
</style>
