<!-- @format -->

<template>
  <div class="entry-div">
    <div class="title-bar">
      <div class="title-text">Send Messages ({{ moptn }})</div>
      <div class="close-btn" @click="closeIt">
        <span class="fas fa-times-circle"></span>
      </div>
    </div>
    <student-track
      @on-select="selectStudent"
      @on-close="closeTrack"
      v-if="studenttrack == true"
    ></student-track>

    <card-loader
      :LoaderText="loaderText"
      v-show="loading == true"
    ></card-loader>
    <div class="div-content" v-show="loading == false">
      <alert-box
        :alertText="alertMsg"
        :alertType="errType"
        v-if="alert"
      ></alert-box>

      <div ref="entryform">
        <div class="err" v-if="err">{{ errMsg }}</div>
        <div>
          <label for="msgtype">Send To : <MandatoryPage /></label>
          <select
            name="msgtype"
            class="form-select"
            v-model="formData.msgtype"
            tabindex="1"
            ref="msgtype"
            @change="msgType()"
          >
            <option :value="''">--Select--</option>
            <option :value="'1'" v-show="$store.state.isTeacher == false">
              School
            </option>
            <option :value="'2'">Class</option>
            <option :value="'3'">Student</option>
          </select>
        </div>
        <div v-if="ShwClass">
          <label>Select Class:</label>
          <multi-selection-chip
            :mydata="allClasses"
            @updated-classes="updateClass"
          ></multi-selection-chip>
          <br />
        </div>

        <div v-if="ShwStudent">
          <label for="glc">Student : <MandatoryPage /></label>
          <br />
          <div class="student-box">
            <input
              ref="glc"
              type="text"
              name="glc"
              class="form-control glcinput"
              v-model="formData.glc"
              autocomplete="off"
              maxlength="250"
              tabindex="3"
              readonly
            />
            <button
              class="sbtn btn-primary"
              type="button"
              @click="studenttrack = !studenttrack"
              ref="glcbtn"
            >
              ...
            </button>
            <input
              ref="gln"
              type="text"
              name="gln"
              class="form-control"
              v-model="formData.gln"
              autocomplete="off"
              maxlength="80"
              tabindex="4"
              readonly
            />
          </div>
        </div>
        <label for="msubject">Message Subject : <MandatoryPage /></label>
        <input
          ref="msubject"
          type="text"
          name="msubject"
          class="form-control"
          v-model="formData.msubject"
          autocomplete="off"
          maxlength="50"
          tabindex="3"
        />

        <label for="msg">Message :</label>
        <textarea
          ref="msg"
          type="text"
          name="msg"
          class="form-control textbox"
          v-model="formData.msg"
          autocomplete="off"
          tabindex="3"
          rows="8"
        />

        <upload-multiple-files
          @files-data="getFiles"
          ref="uploadfiles"
        ></upload-multiple-files>
      </div>
      <div class="btn-div">
        <button
          type="button"
          class="btn btn-success"
          tabindex="4"
          @click="saveRec"
        >
          Save
        </button>
        <button
          type="button"
          class="btn btn-danger"
          tabindex="5"
          @click="closeIt"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import AlertBox from "@/components/AlertBox.vue";
import StudentTrack from "../../components/StudentTrack.vue";
import MultiSelectionChip from "../../components/MultiSelectionChip.vue";
import UploadMultipleFiles from "@/components/UploadMultipleFiles.vue";
export default {
  components: {
    AlertBox,
    StudentTrack,
    MultiSelectionChip,
    UploadMultipleFiles,
  },
  emits: ["close-btn"],
  props: {
    moptn: {
      type: String,
      default: "Add",
      required: false,
    },
  },
  data() {
    return {
      formData: {
        msgtype: "",
        docno: "",
        msg: "",
        class: "",
        cldes: "",
        class1: "",
        sec: "",
        glc: "",
        gln: "",
        msubject: "",
        uploaded_files: [],
        selected_classes: [],
      },
      Cmbcldes: { id: "", text: "", text1: "" },
      userId: this.$store.state.userId,
      userName: this.$store.state.userName,
      err: false,
      errMsg: "",
      errType: "",
      alertMsg: "",
      enableAll: false,
      addAll: false,
      editAll: false,
      delAll: false,
      alert: false,
      loading: false,
      Classes: [],
      allClasses: [],
      userMenu: [],
      loaderText: "Saving",
      ShwClass: false,
      ShwStudent: false,
      studenttrack: false,
      selectedfiles: [],
      upload_directory: "messages",
      trackno: 0,
    };
  },
  methods: {
    async testData() {
      await this.uploadFiles();
      await axios
        .post("test.php", {
          optn: this.moptn,
          frmdata: this.formData,
          userid: this.userId,
          username: this.userName,
          mtrackno: this.trackno,
        })
        .then(async (response) => {
          var resp = response.data;
          this.errMsg = "Saved";
          if (resp.Errorsts == true) {
            this.err = true;
            this.errMsg = "Somthing went Wrong " + resp.ErrorMsg;
          } else {
            this.showAlert();
            this.alertMsg = "Saved";
            this.errType = "success";
          } // endif
        });
      this.formBlank();
    },
    activateForm() {
      setTimeout(() => {
        this.$refs.msgtype.focus();
      }, 100);
    },
    closeIt() {
      // this.$emit("close-btn");
      this.$router.push("/dashboard");
    },
    getFiles(xfiles) {
      this.selectedfiles = xfiles;
    },
    updateClass(xclasses) {
      this.formData.selected_classes = xclasses;
    },
    async uploadFiles() {
      var success = true;
      if (this.selectedfiles.length > 0) {
        const formData = new FormData();
        formData.append("dir", this.upload_directory);
        // Append each selected file to the FormData object
        this.selectedfiles.forEach((file) => {
          formData.append("files[]", file);
        });
        await axios
          .post("uploadfiles.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((resp) => {
            if (resp.data.Errorsts == false) {
              this.formData.uploaded_files = resp.data.Files;
            } else {
              success = false;
              alert(resp.data.ErrorMsg);
            }
          });
        this.$refs.uploadfiles.clearFiles();
      }
      return success;
    },
    async saveRec() {
      this.loading = true;
      this.loaderText = "Saving";

      var msg = "";
      if (this.formData.msgtype == "" || this.formData.msubject == "") {
        msg = "Please Add Message Send To and Message !!";
        this.errMsg = msg;
        this.err = true;
        this.loading = true;
        this.$refs.msgtype.focus();
      } else {
        if (
          this.formData.msgtype == "2" &&
          this.formData.selected_classes.length == 0
        ) {
          msg = "Please Select Class!";
          this.errMsg = msg;
          this.err = true;
          this.loading = true;
        } else {
          if (this.formData.msgtype == "3" && this.formData.glc == "") {
            msg = "Please Select Student!";
            this.errMsg = msg;
            this.err = true;
            this.loading = true;
            this.$refs.glcbtn.focus();
          } else {
            this.err = false;
            var filesuccess = await this.uploadFiles();
            if (filesuccess == true) {
              this.formData.filepath = this.$store.state.filePath;

              // Save & Update
              await axios
                .post("messages.php", {
                  optn: this.moptn,
                  frmdata: this.formData,
                  userid: this.userId,
                  username: this.userName,
                  mtrackno: this.trackno,
                })
                .then(async (response) => {
                  console.log("Save Log");
                  console.log(response);
                  var resp = response.data;
                  this.errMsg = "Saved";
                  if (resp.Errorsts == true) {
                    this.err = true;
                    this.errMsg = "Somthing went Wrong " + resp.ErrorMsg;
                  } else {
                    this.showAlert();
                    this.alertMsg = "Saved";
                    this.errType = "success";
                  } // endif
                });
              this.formBlank();
              if (this.moptn == "Edit") {
                setTimeout(() => {
                  this.closeIt();
                }, 1000);
              } // endif
            } //endif
          }
        }
      }
      this.loading = false;
    },
    formBlank() {
      this.errMsg = "";
      this.err = false;
      this.enableAll = false;
      this.addAll = false;
      this.editAll = false;
      this.delAll = false;
      this.Cmbcldes = { id: "", text: "", text1: "" };

      var self = this;
      Object.keys(this.formData).forEach(function (key) {
        self.formData[key] = "";
      });
      this.formData.selected_classes = [];
      this.formData.uploaded_files = [];
      this.msgType();
      this.activateForm();
    },
    showAlert(xtime = 1500) {
      this.alert = true;
      setTimeout(() => {
        this.alert = false;
      }, xtime);
    },
    async getclmst() {
      await axios
        .post("classes.php", {
          optn: "Classes",
          userid: this.userId,
          isteacher: this.$store.state.isTeacher,
        })
        .then((response) => {
          console.log(response);
          var resp = response.data;
          if (resp.Errorsts == false) {
            this.Classes = resp.List;
          } else {
            console.log("Error:" + resp.ErrorMsg);
          }
        });
    },
    async fillRec(xrecid) {
      this.trackno = xrecid;
      var mstData = "";
      await axios
        .post("messages.php", {
          optn: "Getrec",
          trackno: xrecid,
        })
        .then((response) => {
          var resp = response.data;
          mstData = resp.List;
        });

      var self = this;
      Object.keys(this.formData).forEach(function (key) {
        self.formData[key] = mstData[0][key];
      });
      // await this.msgType();

      var msgtype = mstData[0]["msgtype"];
      if (msgtype == "2") {
        this.ShwClass = true;
        this.ShwStudent = false;
      }
      if (msgtype == "3") {
        this.ShwStudent = true;
        this.ShwClass = false;
      }
      if (msgtype == "1") {
        this.ShwStudent = false;
        this.ShwClass = false;
      }

      this.activateForm();
      this.Cmbcldes.id = this.formData.class;
      this.Cmbcldes.text = this.formData.cldes;
      this.Cmbcldes.text1 = this.formData.class1;

      await this.getSections();

      this.formData.sec = mstData[0]["sec"];
      this.errMsg = "";
      this.err = false;
    },
    msgType() {
      var msgtype = this.formData.msgtype;
      if (msgtype == "2") {
        this.ShwClass = true;
        this.formData.glc = "";
        this.formData.gln = "";
        this.Cmbcldes = { id: "", text: "", text1: "" };
      } else {
        this.ShwClass = false;
      }
      if (msgtype == "3") {
        this.ShwStudent = true;
        this.formData.class = "";
        this.formData.class1 = "";
        this.formData.cldes = "";
        this.formData.sec = "";
      } else {
        this.ShwStudent = false;
      }
      if (msgtype == "1") {
        this.ShwStudent = false;
        this.ShwClass = false;
        this.formData.class = "";
        this.formData.class1 = "";
        this.formData.cldes = "";
        this.formData.sec = "";
        this.formData.glc = "";
        this.formData.gln = "";
      }
    },
    selectStudent(obj) {
      this.formData.glc = obj.glc;
      this.formData.gln = obj.gln;
      this.closeTrack();
    },
    closeTrack() {
      this.studenttrack = !this.studenttrack;
    },
    async getSections() {
      var q1 = "";
      if (this.$store.state.isTeacher == true) {
        q1 =
          "and EXISTS (select class,class1 from userclasses_view c " +
          "where a.class=c.class and a.class1=c.class1 and c.uid='" +
          this.userId +
          "' group by c.class,c.class1)";
      }

      var q =
        "select a.class,a.class1,a.cldes,a.sec,a.trackno,b.ord " +
        "from classec a join classes b on a.class=b.class and a.class1=b.class1 " +
        q1 +
        "order by b.ord,a.sec";
      await axios
        .post("getjson.php", {
          optn: "GetData",
          qry: q,
        })
        .then((response) => {
          var resp = response.data;
          if (resp.Errorsts == false) {
            this.allClasses = resp.List;
            var myall = {
              class: "ALL",
              class1: "",
              cldes: "ALL",
              sec: "",
              trackno: 0,
              ord: 0,
            };
            this.allClasses.unshift(myall);

            this.formData.sec = "";
          } else {
            console.log("Error:" + resp.ErrorMsg);
          }
        });
    },
  },
  mounted() {
    this.loading = true;
    this.formBlank();
    this.getclmst();
    this.getSections();
    this.loading = false;
  },
};
</script>

<style>
@import "./css/master.css";
</style>
<style scoped>
.glcinput {
  width: 200px;
}
.student-box {
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
}
.textbox {
  resize: none;
}
</style>
