<template>
  <div class="loader-div">
    <i class="fa fa-spinner fa-spin"></i>
    <br />
    <br />
    <br />
    <b>{{ LoaderText }}</b>
  </div>
  <div class="bgdiv"></div>
</template>

<script>
export default {
  props: ["LoaderText"],
};
</script>
<style scoped>
.bgdiv {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: #00000053;
  z-index: 99;
}
.loader-div {
  position: absolute;
  margin: 0px auto !important;
  width: 150px;
  height: 150px;
  border-radius: 15px;
  text-align: center;
  top: 30%;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: white;
  z-index: 99999;
}
.fa-spinner {
  position: relative;
  margin: 0px auto !important;
  top: 25%;
  font-size: 45px;
}
</style>
