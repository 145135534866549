<template>
  <div>
    <canvas :id="ChartId" class="canvascenter"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
export default {
  name: "PieChart",
  props: ["ChartId", "ChartLabel", "ChartData", "ChartColor", "ChartHead"],
  data() {
    return {
      pieChartData: {
        type: "pie",
        data: {
          labels: this.ChartLabel,
          datasets: [
            {
              label: this.ChartHead,
              backgroundColor: this.ChartColor,
              data: this.ChartData,
            },
          ],
        },
        options: {
          responsive: false,
          plugins: {
            legend: {
              display: true,
            },
          },
        },
      },
    };
  },
  methods: {
    refreshChart() {
      const ctx = document.getElementById(this.ChartId);
      ctx.height = 250;
      ctx.width = 350;
      new Chart(ctx, this.pieChartData);
    },
  },
};
</script>
<style scoped>
.canvascenter {
  margin: 0px auto;
}
</style>
