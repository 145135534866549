<!-- @format -->

<template>
  <user-add
    :moptn="optn"
    v-show="addForm"
    @close-btn="formClose"
    ref="vendorForm"
  ></user-add>

  <div class="form-div" v-show="!addForm">
    <alert-box
      :alertText="errMsg"
      :alertType="errType"
      v-if="alert"
    ></alert-box>
    <div class="title-bar">
      <div class="title-text">Users List</div>
      <div class="close-btn">
        <router-link to="/dashboard">
          <span class="fas fa-times-circle"></span
        ></router-link>
      </div>
    </div>
    <div class="content-area">
      <div class="btn-area">
        <button
          type="button"
          class="btn btn-primary"
          @click="addRec"
          v-if="permission.add"
        >
          Add
        </button>
        &nbsp; &nbsp;
        <button type="button" class="btn btn-info" @click="exportRep">
          Export
        </button>

        <div class="exportlink">
          <span v-show="exporting"></span>
          <a :href="fileLink">{{ fileName }}</a>
        </div>
      </div>
      <div class="filter-area">
        <input
          type="text"
          class="form-control searchbox"
          placeholder="Search"
          v-model="search"
          @keyup="getRec"
          ref="search"
          autofocus
        />
      </div>
      <div class="grid-area">
        <card-loader v-if="loading"></card-loader>
        <table border="1" v-else>
          <thead>
            <tr>
              <th class="editcol"></th>
              <th class="srnocol">#</th>
              <th>User Name</th>
              <th>Teacher Name</th>
              <th>Class</th>
              <th>Created By</th>
              <th>Edited By</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in mstList" :key="row.uid">
              <td>
                &nbsp;<span
                  class="fas fa-edit"
                  @click="editRec(row.uid)"
                  v-if="permission.edit"
                ></span>
                &nbsp;
                <span
                  class="fas fa-trash"
                  @click="delRec(row.uid, row.uname)"
                  v-if="permission.delete"
                >
                </span>
                &nbsp;
              </td>
              <td>{{ index + 1 }}</td>
              <td>{{ row.uname }}</td>
              <td>{{ row.ename }}</td>
              <td>{{ getDetail(row.uid) }}</td>
              <td>
                {{ row.createdby
                }}<span class="smalldt">{{ row.created_dt }}</span>
              </td>
              <td>
                {{ row.editedby
                }}<span class="smalldt">{{ row.edited_dt }}</span>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="7"></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import AlertBox from "@/components/AlertBox.vue";
import CardLoader from "@/components/CardLoader.vue";
import axios from "axios";
import UserAdd from "./UserAdd.vue";
import MyFun from "@/js/MyFun";

export default {
  components: {
    UserAdd,
    AlertBox,
    CardLoader,
  },
  data() {
    return {
      formId: "004",
      permission: [],
      addForm: false,
      optn: "Add",
      search: "",
      mstList: [],
      mstDet: [],
      userId: this.$store.state.userId,
      userName: this.$store.state.userName,
      errMsg: "",
      errType: "",
      alert: false,
      loading: false,
      fileName: "",
      fileLink: "",
      exporting: false,
    };
  },
  methods: {
    addRec() {
      this.optn = "Add";
      this.addForm = true;
      this.$refs.vendorForm.formBlank();
    },
    editRec(xid) {
      this.$refs.vendorForm.fillRec(xid);
      this.optn = "Edit";
      this.addForm = true;
      this.getRec();
    },
    async delRec(xid, xname) {
      var yn = confirm("Want to Delete?");
      if (yn == true) {
        this.optn = "Delete";
        await axios
          .post("users.php", {
            optn: "Delete",
            uid: xid,
            uname: xname,
            userid: this.userId,
            username: this.userName,
          })
          .then((response) => {
            var resp = response.data;

            if (resp.Errorsts == true) {
              console.log(resp.ErrorMsg);
            } else {
              if (resp.RowCount > 0) {
                this.errType = "danger";
                this.errMsg = resp.ErrorMsg;
                this.showAlert(3000);
              } else {
                this.getRec();
              }
            }
          });
      }
    },
    async getRec() {
      this.loading = true;
      await axios
        .post("users.php", {
          optn: "List",
          msearch: this.search,
          userid: this.userId,
          username: this.userName,
        })
        .then((response) => {
          // console.log(response);
          var resp = response.data;
          if (resp.Errorsts == false) {
            this.mstList = resp.List;
            this.mstDet = resp.Listdet;
          } else {
            console.log("Error:" + this.ErroMsg);
          }
        });
      this.loading = false;
    },
    async exportRep() {
      this.exporting = true;
      await axios
        .post("users.php", {
          optn: "AdminUsers",
          msearch: this.search,
        })
        .then((response) => {
          this.exporting = false;
          var resp = response.data;
          if (resp.Errorsts == false) {
            this.fileName = resp.FileName;
            this.fileLink = resp.FileLink;
          } else {
            console.log("Error:" + this.ErroMsg);
          }
        });
    },
    formClose() {
      this.addForm = false;
      this.getRec();
    },
    showAlert(xtime = 1500) {
      this.alert = true;
      setTimeout(() => {
        this.alert = false;
      }, xtime);
    },
    getDetail(xuid) {
      var myarr;
      myarr = this.mstDet.filter((rw) => {
        return rw.uid == xuid;
      });

      var mycls = "";
      myarr.map((r) => {
        mycls += r.cldes + ",";
      });
      return mycls.substr(0, mycls.length - 1);
    },
  },
  async mounted() {
    this.getRec();
    this.permission = await MyFun.getPermissions(this.userId, this.formId);
  },
  computed: {},
};
</script>

<style scoped>
.form-div {
  position: relative;
  margin: 5px;
  padding: 0px;
  width: calc(100% - 10px);
  border-radius: 5px;
  background-color: white;
  border: 1px solid lightgray;
  top: 10px;
}

.title-bar {
  position: relative;
  display: block;
  padding: 10px;
  width: 100%;
  background-color: #242424;
  color: whitesmoke;
  font-weight: bold;
  text-align: left;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.title-bar div {
  display: inline-block;
}
.close-btn {
  position: relative;
  justify-content: right;
  right: 0px;
  float: right;
  cursor: pointer;
}
.close-btn a {
  position: relative;
  color: white;
  font-size: 20px;
  top: -3px;
}
.close-btn a:hover {
  color: red;
}
.content-area {
  padding: 15px;
}

.btn-area {
  position: relative;
  display: block;
  padding: 5px;
}
.btn-area .exportbtn {
  position: relative;
  float: right;
}

.filter-area {
  padding: 5px;
}

.grid-area {
  padding: 2px;

  width: 100%;
  background-color: white;
  max-height: calc(100vh - (250px));
  overflow: auto;
}
.grid-area table {
  width: 100%;
}
.grid-area table thead {
  position: sticky;
  top: 0px;
  background-color: #242424;
  color: white;
}
.grid-area table tfoot {
  position: sticky;
  bottom: 0px;
  background-color: #242424;
  color: white;
}

.grid-area table thead tr th {
  font-weight: 400;
  padding: 5px;
}

.grid-area table tbody tr td {
  cursor: pointer;
  padding: 5px;
  border-bottom: 0.5px solid lightgray;
}
.grid-area table tbody tr:hover {
  background-color: lightgray;
}

.editcol {
  width: 80px;
}
.srnocol {
  width: 60px;
}

@media only screen and (max-width: 600px) {
  .grid-area table thead tr th,
  .grid-area table tbody tr td,
  .grid-area table tfoot tr td {
    padding: 2px;
    font-size: 12px;
  }
  .smalldt {
    position: relative;
    display: block;
    font-size: xx-small;
    font-weight: bold;
  }
}
</style>
