<!-- @format -->
<template>
  <div class="entry-div">
    <div class="title-bar">
      <div class="title-text">Upload Gallery ({{ moptn }})</div>
      <div class="close-btn" @click="closeIt">
        <span class="fas fa-times-circle"></span>
      </div>
    </div>

    <card-loader
      :LoaderText="loaderText"
      v-show="loading == true"
    ></card-loader>
    <div class="div-content" v-show="loading == false">
      <alert-box
        :alertText="alertMsg"
        :alertType="errType"
        v-if="alert"
      ></alert-box>

      <div ref="entryform">
        <div class="err" v-if="err">{{ errMsg }}</div>
        <label for="msubject">Subject : <MandatoryPage /></label>
        <input
          ref="msubject"
          type="text"
          name="msubject"
          class="form-control"
          v-model="formData.msubject"
          autocomplete="off"
          maxlength="50"
          tabindex="3"
        />

        <label for="msg">Message : </label>
        <textarea
          ref="msg"
          type="text"
          name="msg"
          class="form-control textbox"
          v-model="formData.msg"
          autocomplete="off"
          tabindex="3"
          rows="8"
        />

        <upload-multiple-files
          @files-data="getFiles"
          ref="uploadfiles"
        ></upload-multiple-files>
      </div>
      <div class="btn-div">
        <button
          type="button"
          class="btn btn-success"
          tabindex="4"
          @click="saveRec"
        >
          Save
        </button>
        <button
          type="button"
          class="btn btn-danger"
          tabindex="5"
          @click="closeIt"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import AlertBox from "@/components/AlertBox.vue";
import UploadMultipleFiles from "@/components/UploadMultipleFiles.vue";
export default {
  components: {
    AlertBox,
    UploadMultipleFiles,
  },
  emits: ["close-btn"],
  props: {
    moptn: {
      type: String,
      default: "Add",
      required: false,
    },
  },
  data() {
    return {
      formData: {
        docno: "",
        msg: "",
        class: "",
        cldes: "",
        class1: "",
        sec: "",
        glc: "",
        gln: "",
        msubject: "",
        uploaded_files: [],
        selected_classes: [],
        filepath: "",
      },
      Cmbcldes: { id: "", text: "", text1: "" },
      userId: this.$store.state.userId,
      userName: this.$store.state.userName,
      err: false,
      errMsg: "",
      errType: "",
      alertMsg: "",
      enableAll: false,
      addAll: false,
      editAll: false,
      delAll: false,
      alert: false,
      loading: false,
      Classes: [],
      allClasses: [],
      userMenu: [],
      loaderText: "Saving",
      ShwClass: false,
      ShwStudent: false,
      selectedfiles: [],
      upload_directory: "gallery",
      trackno: 0,
      classloaded: false,
    };
  },
  methods: {
    activateForm() {
      setTimeout(() => {
        // this.$refs.msgtype.focus();
      }, 100);
    },
    closeIt() {
      // this.$emit("close-btn");
      this.$router.push("/dashboard");
    },
    getFiles(xfiles) {
      this.selectedfiles = xfiles;
    },
    updateClass(xclasses) {
      this.formData.selected_classes = xclasses;
    },
    async uploadFiles() {
      var success = true;
      if (this.selectedfiles.length > 0) {
        const formData = new FormData();
        formData.append("dir", this.upload_directory);
        // Append each selected file to the FormData object
        this.selectedfiles.forEach((file) => {
          formData.append("files[]", file);
        });
        await axios
          .post("uploadfiles.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((resp) => {
            if (resp.data.Errorsts == false) {
              this.formData.uploaded_files = resp.data.Files;
            } else {
              success = false;
              alert(resp.data.ErrorMsg);
            }
          });
        this.$refs.uploadfiles.clearFiles();
      }
      return success;
    },
    async saveRec() {
      this.loading = true;
      this.loaderText = "Saving";
      this.formData.filepath = this.$store.state.filePath;
      var msg = "";
      if (this.formData.msubject == "" || this.selectedfiles.length == 0) {
        msg = "Please Add Message & Select File to Upload!";
        this.errMsg = msg;
        this.err = true;
        this.loading = false;
      } else {
        this.err = false;
        var filesuccess = await this.uploadFiles();
        if (filesuccess == true) {
          // Save & Update
          await axios
            .post("gallery.php", {
              optn: this.moptn,
              frmdata: this.formData,
              userid: this.userId,
              username: this.userName,
              mtrackno: this.trackno,
            })
            .then(async (response) => {
              console.log(response);

              var resp = response.data;
              this.errMsg = "Saved";
              if (resp.Errorsts == true) {
                this.err = true;
                this.errMsg = "Somthing went Wrong " + resp.ErrorMsg;
              } else {
                this.showAlert();
                this.alertMsg = "Saved";
                this.errType = "success";
              } // endif
            });
          this.loading = false;

          this.formBlank();
          if (this.moptn == "Edit") {
            setTimeout(() => {
              this.closeIt();
            }, 1000);
          } // endif
        } //endif
      }
    },
    formBlank() {
      this.errMsg = "";
      this.err = false;
      this.enableAll = false;
      this.addAll = false;
      this.editAll = false;
      this.delAll = false;
      this.Cmbcldes = { id: "", text: "", text1: "" };

      var self = this;
      Object.keys(this.formData).forEach(function (key) {
        self.formData[key] = "";
      });
      this.formData.selected_classes = [];
      this.formData.uploaded_files = [];
      this.activateForm();
    },
    showAlert(xtime = 1500) {
      this.alert = true;
      setTimeout(() => {
        this.alert = false;
      }, xtime);
    },
    async fillRec(xrecid) {
      this.trackno = xrecid;
      var mstData = "";
      await axios
        .post("gallery.php", {
          optn: "Getrec",
          trackno: xrecid,
        })
        .then((response) => {
          var resp = response.data;
          mstData = resp.List;
        });

      var self = this;
      Object.keys(this.formData).forEach(function (key) {
        self.formData[key] = mstData[0][key];
      });

      this.ShwClass = true;

      this.activateForm();
      this.Cmbcldes.id = this.formData.class;
      this.Cmbcldes.text = this.formData.cldes;
      this.Cmbcldes.text1 = this.formData.class1;

      await this.getSections();

      this.formData.sec = mstData[0]["sec"];
      this.errMsg = "";
      this.err = false;
    },
    async getSections() {
      var q1 = "";
      if (this.$store.state.isTeacher == true) {
        q1 =
          "and EXISTS (select class,class1 from userclasses_view c " +
          "where a.class=c.class and a.class1=c.class1 and c.uid='" +
          this.userId +
          "' group by c.class,c.class1)";
      }

      var q =
        "select a.class,a.class1,a.cldes,a.sec,a.trackno,b.ord " +
        "from classec a join classes b on a.class=b.class and a.class1=b.class1 " +
        q1 +
        "order by b.ord";
      console.log(q);
      await axios
        .post("getjson.php", {
          optn: "GetData",
          qry: q,
        })
        .then((response) => {
          var resp = response.data;
          if (resp.Errorsts == false) {
            this.allClasses = resp.List;
            var myall = {
              class: "ALL",
              class1: "",
              cldes: "ALL",
              sec: "",
              trackno: 0,
              ord: 0,
            };
            this.allClasses.unshift(myall);
            this.formData.sec = "";
            this.classloaded = true;
          } else {
            console.log("Error:" + resp.ErrorMsg);
          }
        });
    },
  },
  async mounted() {
    this.loading = true;
    this.formBlank();
    await this.getSections();
    this.loading = false;
  },
};
</script>

<style>
@import "./css/master.css";
</style>
<style scoped>
.glcinput {
  width: 200px;
}
.student-box {
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
}
.textbox {
  resize: none;
}
</style>
