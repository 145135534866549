<template>
  <div class="dash-div">
    <div class="menu-list">
      <base-card
        v-for="menu in menulist"
        :key="menu.optn"
        :CardTitle="menu.optdes"
        :CardIcon="menu.icons"
        @click="toPage(menu.page)"
      ></base-card>
    </div>

    <div class="allcharts" v-if="showDashboard">
      <absent-chart></absent-chart>
      <message-chart></message-chart>
      <app-users></app-users>
    </div>
  </div>
</template>

<script>
import BaseCard from "../components/BaseCard.vue";
import MessageChart from "@/components/Widgets/MessageChart.vue";
import AppUsers from "@/components/Widgets/AppUsers.vue";
import AbsentChart from "@/components/Widgets/AbsentChart.vue";
import axios from "axios";

export default {
  components: {
    BaseCard,
    MessageChart,
    AppUsers,
    AbsentChart,
  },
  data() {
    return {
      StoreUser: this.$store.state.storeFlg,
      showDashboard: this.$store.state.showDash,
      menulist: [],
    };
  },
  methods: {
    toPage(xpg) {
      this.$router.push("/" + xpg);
    },
    async getMenu() {
      var menu = [];
      await axios
        .post("users.php", {
          optn: "GetMenu",
          uid: this.$store.state.userId,
        })
        .then((response) => {
          var resp = response.data;
          if (resp.Errorsts == false) {
            menu = resp.List;

            for (var i = 0; i < menu.length; i++) {
              // var catg = menu[i].catg;
              var obj = { name: "", to: "" };
              obj.name = menu[i].optdes;
              obj.to = menu[i].page;
            }
          } else {
            console.log("Error:" + resp.ErrorMsg);
          }
        });
      this.menulist = menu;
    },
  },
  async mounted() {
    this.getMenu();
  },
};
</script>
<style scoped>
.dash-div {
  position: relative;
  top: 10px;
  text-align: center;
}

.menu-list {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 15px;
  padding: 0px;
}
/* .dash-div div {
  position: relative;
  display: block;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  height: fit-content;
}
.dash-div div div {
  display: inline-block;
} */
.allcharts {
  display: inline-flex;
  position: relative;
  justify-content: left;
  flex-wrap: wrap;
  padding-bottom: 20px;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .menu-list {
    margin-top: 0px;
    top: -20px;
  }
}
</style>
