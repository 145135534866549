<template>
  <div>
    <div class="read-list">
      <div class="title">
        Students Seen
        <div class="close-btn" @click="hideBox">
          <span class="fas fa-times-circle"></span>
        </div>
      </div>
      <div class="student-list">
        Class:{{ des }}
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Id</th>
              <th>Student Name</th>
              <th>Seen At</th>
            </tr>
          </thead>
          <tbody v-if="studentlist.length > 0">
            <tr v-for="(row, index) in studentlist" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ row.glc }}</td>
              <td>{{ row.gln }}</td>
              <td>{{ row.seentm }}</td>
            </tr>
          </tbody>
          <tr v-else>
            <td colspan="4" class="tocenter">No Record Found!</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="bg-div"></div>
  </div>
</template>

<script>
export default {
  emits: ["hidebox"],
  data() {
    return {
      studentlist: [],
      des: "",
    };
  },
  methods: {
    async getReaddata(xrw) {
      this.des = xrw.des;
      this.studentlist = xrw.list;
    },
    hideBox() {
      this.$emit("hidebox");
    },
  },
};
</script>

<style scoped>
.read-list {
  position: fixed;
  border: 0px solid transparent;
  border-radius: 6px;
  width: 450px;
  height: 550px;
  overflow: auto;
  background-color: white;
  box-shadow: 0px 0px 8px 0px rgb(46, 46, 46);
  left: calc(50% - 350px);
  top: 80px;
  z-index: 9999;
}

.title {
  position: relative;
  width: 100%;
  padding: 6px;
  color: white;
  background-color: black;
}

.student-list {
  position: relative;
  padding: 8px;
  max-height: 500px;
  overflow-y: scroll;
}

.student-list table thead {
  position: sticky;
  top: -8px;
  background-color: black;
  color: white;
}
.bg-div {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100%;
  background-color: #00000067;
}

@media only screen and (max-width: 600px) {
  .read-list {
    width: calc(100% - 10px);
    left: 0px;
    right: 0px;
    margin: 0px auto !important;
  }
}
</style>
