<template>
  <widget-box>
    <template #title> App Users </template>
    <template #default>
      <card-loader v-if="loading"></card-loader>
      <div class="wid_body" v-else>
        <pie-chart
          ref="UserChart"
          :ChartData="MyData"
          :ChartLabel="MyLabel"
          :ChartId="'AppUserChart'"
          :ChartColor="ChartColor"
          :ChartHead="ChartHead"
          :LegendDisplay="false"
        ></pie-chart>
      </div>
    </template>
  </widget-box>
</template>

<script>
import axios from "axios";
import WidgetBox from "./WidgetBox.vue";
import PieChart from "../Charts/PieChart.vue";
import MyFun from "@/js/MyFun";

export default {
  name: "UserChart",
  components: {
    PieChart,
    WidgetBox,
  },
  data() {
    return {
      outletid: "",
      userId: this.$store.state.userId,
      userName: this.$store.state.userName,
      datalist: [],
      loading: false,
      MyLabel: [],
      MyData: [],
      ChartColor: [
        "#19e45c", // green
        "rgb(211, 32, 32)", //red
      ],
      ChartHead: "App Users",
      docdt: MyFun.getdtnow(),
    };
  },
  methods: {
    async getUserCount() {
      this.loading = true;

      this.MyLabel = [];
      this.MyData = [];
      await axios
        .post("widgets.php", {
          optn: "AppUsers",
          docdt: this.docdt,
        })
        .then((response) => {
          if (response.data.Errorsts == false) {
            this.datalist = response.data.List;
            this.loading = false;
            for (var i = 0; i < response.data.List.length; i++) {
              this.MyLabel.push(response.data.List[i]["label"]);
              this.MyData.push(parseFloat(response.data.List[i]["data"]));
            }
          }
        });
      await this.$refs.UserChart.refreshChart();
    },
  },
  async mounted() {
    await this.getUserCount();
  },
};
</script>

<style src="./widget.css" scoped></style>
