<!-- @format -->

<template>
  <div class="entry-div">
    <div class="title-bar">
      <div class="title-text">Users ({{ moptn }})</div>
      <div class="close-btn" @click="closeIt">
        <span class="fas fa-times-circle"></span>
      </div>
    </div>
    <card-loader :LoaderText="loaderText" v-if="loading"></card-loader>
    <div class="div-content" v-else>
      <alert-box
        :alertText="alertMsg"
        :alertType="errType"
        v-if="alert"
      ></alert-box>

      <form ref="entryform">
        <div class="err" v-if="err">{{ errMsg }}</div>
        <label for="uname">User Name : <MandatoryPage /></label>
        <input
          type="hidden"
          v-model="formData.uid"
          :disabled="moptn == 'Edit'"
        />
        <input
          type="text"
          name="uname"
          class="form-control"
          v-model="formData.uname"
          maxlength="20"
          autocomplete="off"
          ref="uname"
          @blur="findUser"
          tabindex="1"
        />
        <label for="upass">Password : <MandatoryPage /></label>
        <input
          type="password"
          name="upass"
          class="form-control"
          v-model="formData.upass"
          maxlength="20"
          autocomplete="off"
          ref="upass"
          tabindex="2"
        />
        <span
          class="showpass fas"
          :class="{ 'fa-eye': pwdicon, 'fa-eye-slash': !pwdicon }"
          @click="showPass()"
        ></span>
        <div class="form-group">
          <label for="stflg">Teacher(Y/N): <MandatoryPage /></label>
          <input
            type="text"
            class="form-control smallbox"
            v-model="formData.catg"
            maxlength="1"
            autocomplete="off"
            ref="catg"
            @blur="checkFlg('catg')"
            tabindex="3"
          />
          <button type="button" class="btn btn-primary" @click="toggleClasses">
            Select Classes
          </button>
        </div>

        <div v-if="formData.catg == 'Y'">
          <label for="depdes">Teacher Name: <MandatoryPage /></label>
          <input
            type="text"
            class="form-control"
            v-model="formData.ename"
            maxlength="50"
            autocomplete="off"
            ref="ename"
            tabindex="3"
          />
        </div>

        <label for="rem">Remarks :</label>
        <input
          type="text"
          name="phone"
          class="form-control"
          v-model="formData.rem"
          autocomplete="off"
          maxlength="30"
          tabindex="5"
        />

        <div class="class-list" v-if="showclass == true">
          <div class="title-bar">
            <u>Classes</u>
            <button
              type="button"
              class="btn btn-primary sbtn"
              @click="toggleClasses()"
            >
              Done
            </button>
          </div>
          <div class="err" v-if="clserr">{{ clserrMsg }}</div>

          <ul>
            <li v-for="rw in classList" :key="rw.class + rw.class1">
              <input
                type="checkbox"
                v-model="rw.selflg"
                @click="rw.selflg = !rw.selflg"
                :id="'C' + rw.classid"
              />
              <label :for="'C' + rw.classid">{{ rw.cldes }}</label>
            </li>
          </ul>
        </div>
        <div class="bgdark" v-if="showclass == true"></div>

        <div class="userperm">
          <table class="table">
            <thead>
              <tr>
                <th>Menu Name</th>
                <th class="tocenter">
                  <label for="enable">Enable &nbsp;</label>
                  <input
                    id="enable"
                    type="checkbox"
                    v-model="enableAll"
                    @click="selectAll('eflg', !enableAll)"
                  />
                </th>
                <th class="tocenter">
                  <label for="add">Add &nbsp;</label
                  ><input
                    id="add"
                    type="checkbox"
                    v-model="addAll"
                    @click="selectAll('aflg', !addAll)"
                  />
                </th>
                <th class="tocenter">
                  <label for="edit">Modify &nbsp;</label>
                  <input
                    id="edit"
                    type="checkbox"
                    v-model="editAll"
                    @click="selectAll('mflg', !editAll)"
                  />
                </th>
                <th class="tocenter">
                  <label for="dele">Delete &nbsp;</label>
                  <input
                    id="dele"
                    type="checkbox"
                    v-model="delAll"
                    @click="selectAll('dflg', !delAll)"
                  />
                </th>
              </tr>
            </thead>
            <tr v-for="item in userMenu" :key="item.optn">
              <td>{{ item.optdes }}</td>
              <td class="tocenter">
                <input
                  type="checkbox"
                  :id="item.optn + 'eflg'"
                  v-model="item.eflg"
                />
              </td>
              <td class="tocenter">
                <input
                  type="checkbox"
                  :id="item.optn + 'aflg'"
                  v-model="item.aflg"
                />
              </td>
              <td class="tocenter">
                <input
                  type="checkbox"
                  :id="item.optn + 'mflg'"
                  v-model="item.mflg"
                />
              </td>
              <td class="tocenter">
                <input
                  type="checkbox"
                  :id="item.optn + 'dflg'"
                  v-model="item.dflg"
                />
              </td>
            </tr>
          </table>
        </div>
      </form>
      <div class="btn-div">
        <button type="button" class="btn btn-success" @click="saveRec">
          Save
        </button>
        <button type="button" class="btn btn-danger" @click="closeIt">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import AlertBox from "@/components/AlertBox.vue";
export default {
  components: { AlertBox },
  emits: ["close-btn"],
  props: ["moptn"],
  data() {
    return {
      formData: {
        uid: "",
        uname: "",
        upass: "",
        cupass: "",
        rem: "",
        catg: "Y",
        ename: "",
      },
      userId: this.$store.state.userId,
      userName: this.$store.state.userName,
      clserr: false,
      clserrMsg: "",
      err: false,
      errMsg: "",
      errType: "",
      alertMsg: "",
      enableAll: false,
      addAll: false,
      editAll: false,
      delAll: false,
      alert: false,
      loading: false,
      pwdicon: true,
      Departments: [],
      userMenu: [],
      loaderText: "Saving",
      classList: [],
      showclass: false,
    };
  },
  methods: {
    closeIt() {
      this.$emit("close-btn");
    },
    activateForm() {
      setTimeout(() => {
        this.$refs.uname.focus();
      }, 100);
    },
    async toggleClasses() {
      this.showclass = !this.showclass;
      this.clserrMsg = "";
      this.clserr = false;

      if (this.showclass == false) {
        var empty = true;
        var classlen = this.classList.length;
        for (let index = 0; index < classlen; index++) {
          if (this.classList[index]["selflg"] == true) {
            empty = false;
            break;
          }
        }
        if (empty == true) {
          this.showclass = !this.showclass;
          var msg = "Please Select Atleast One Class";
          this.clserrMsg = msg;
          this.clserr = true;
        }
      }
    },
    isClassSelected() {
      var selected = [];
      var isvalid = false;
      this.classList.forEach((ele) => {
        if (ele.selflg == true) {
          selected.push(ele);
        }
      });
      if (selected.length > 0) {
        isvalid = true;
      }
      return isvalid;
    },
    showPass() {
      if (this.pwdicon == true) {
        this.$refs.upass.type = "text";
      } else {
        this.$refs.upass.type = "password";
      }
      this.pwdicon = !this.pwdicon;
    },
    async isEmpty() {
      var isempty = false;
      if (this.formData.uname == "" || this.formData.upass == "") {
        isempty = true;
      }
      if (
        this.formData.catg == "Y" &&
        ((await this.isClassSelected()) == false || this.formData.ename == "")
      ) {
        isempty = true;
      }

      return isempty;
    },
    async saveRec() {
      this.loading = true;
      this.loaderText = "Saving";
      var msg = "";
      if (await this.isEmpty()) {
        msg = "Please Enter Mandatory Fields !";
        if (this.formData.catg == "Y") {
          msg += " Please Select Class & Enter Teacher Name !";
        }
        this.errMsg = msg;
        this.err = true;
        this.loading = true;
      } else {
        this.err = false;
        // Save & Update
        await axios
          .post("users.php", {
            optn: this.moptn,
            uid: this.formData.uid,
            uname: this.formData.uname.toUpperCase(),
            upass: this.formData.upass.toUpperCase(),
            rem: this.formData.rem,
            catg: this.formData.catg,
            ename: this.formData.ename,
            userid: this.userId,
            username: this.userName,
            userdet: this.userMenu,
            userclasses: this.classList,
          })
          .then((response) => {
            console.log(response);
            var resp = response.data;
            this.errMsg = "Saved";
            if (resp.Errorsts == true) {
              this.err = true;
              this.errMsg = "Somthing went Wrong " + resp.ErrorMsg;
            } else {
              this.showAlert();
              this.alertMsg = "Saved";
              this.errType = "success";
            } // endif
          });

        this.formBlank();
        if (this.moptn == "Edit") {
          this.closeIt();
        } // endif
      }
      this.loading = false;
    },
    checkFlg(xflg) {
      var self = this;
      self.formData[xflg] = self.formData[xflg].toUpperCase();
      var flgval = self.formData[xflg];
      if (flgval != "Y" && flgval != "N") {
        self.formData[xflg] = "N";
      }
      this.getUserMenu();
    },
    async formBlank() {
      this.errMsg = "";
      this.err = false;
      this.enableAll = false;
      this.addAll = false;
      this.editAll = false;
      this.delAll = false;
      this.Cmbgdepdes = { id: "", text: "" };

      var self = this;
      Object.keys(this.formData).forEach(function (key) {
        self.formData[key] = "";
      });
      await this.genID();
      await this.getClasses();
      await this.getUserMenu();
      await this.activateForm();
    },
    showAlert(xtime = 1500) {
      this.alert = true;
      setTimeout(() => {
        this.alert = false;
      }, xtime);
    },
    async getClasses() {
      var classarr = [];
      this.classList = [];

      await axios
        .post("classes.php", {
          optn: "ClassSecList",
          uid: this.formData.uid,
          msearch: "",
        })
        .then((response) => {
          var resp = response.data;
          if (resp.Errorsts == false) {
            classarr = resp.List;
          } else {
            console.log("Error:" + resp.ErrorMsg);
          }
        });

      this.classList = classarr;
      var classlen = this.classList.length;
      for (let index = 0; index < classlen; index++) {
        if (this.classList[index]["selflg"] == 1) {
          this.classList[index]["selflg"] = true;
        } else {
          this.classList[index]["selflg"] = false;
        }
      }
    },
    async fillRec(xrecid) {
      this.loaderText = "Loading";
      this.loading = true;
      this.formData.uname = xrecid;
      var mstData = "";
      await axios
        .post("users.php", {
          optn: "Getrec",
          uid: xrecid,
          userid: this.userId,
          username: this.userName,
        })
        .then((response) => {
          var resp = response.data;
          mstData = resp.List;
        });

      var self = this;
      Object.keys(this.formData).forEach(function (key) {
        self.formData[key] = mstData[0][key];
      });
      // console.log(this.formData.uid);
      await this.getUserMenu();
      // console.log("Getting Classes");
      await this.getClasses();

      this.activateForm();
      this.loading = false;
    },
    async findUser() {
      await axios
        .post("users.php", {
          optn: "Finduser",
          uid: this.formData.uid,
          uname: this.formData.uname,
        })
        .then((response) => {
          var resp = response.data;
          if (resp.Errorsts == false) {
            if (resp.RowCount > 0) {
              this.errType = "danger";
              this.alertMsg = resp.ErrorMsg;
              this.showAlert(3000);
              this.$refs.uname.focus();
            }
          } else {
            console.log(resp.ErrorMsg);
          }
        });
    },
    async getUserMenu() {
      await axios
        .post("users.php", {
          optn: "Getusermenu",
          uid: this.formData.uid,
          userid: this.userId,
          username: this.userName,
        })
        .then((response) => {
          this.userMenu = response.data.List;
        });

      if (this.formData.stflg == "N") {
        // NOT A STORE USER
        this.userMenu = this.userMenu.filter((i) => {
          // Return Demand Note Only
          return i.optn == "013";
        });
      }
      var ll = this.userMenu.length;
      var i = 0;
      for (i = 0; i < ll; i++) {
        if (this.userMenu[i].eflg == 1) {
          this.userMenu[i].eflg = true;
        } else {
          this.userMenu[i].eflg = false;
        }

        if (this.userMenu[i].aflg == 1) {
          this.userMenu[i].aflg = true;
        } else {
          this.userMenu[i].aflg = false;
        }

        if (this.userMenu[i].mflg == 1) {
          this.userMenu[i].mflg = true;
        } else {
          this.userMenu[i].mflg = false;
        }

        if (this.userMenu[i].dflg == 1) {
          this.userMenu[i].dflg = true;
        } else {
          this.userMenu[i].dflg = false;
        }
      }
    },
    async saveUserPerm() {
      await axios
        .post("users.php", {
          optn: "Savedet",
          uid: this.formData.uid,
        })
        .then((response) => {
          if (response.data.Errorsts == true) {
            console.log(response);
          }
        });
    },
    selectAll(xflg, xval) {
      var ll = this.userMenu.length;
      var i = 0;
      for (i = 0; i < ll; i++) {
        if (xflg == "eflg") {
          this.userMenu[i].eflg = xval;
        }
        if (xflg == "aflg") {
          this.userMenu[i].aflg = xval;
        }
        if (xflg == "mflg") {
          this.userMenu[i].mflg = xval;
        }
        if (xflg == "dflg") {
          this.userMenu[i].dflg = xval;
        }
      }
    },
    async genID() {
      var muid = "";
      await axios
        .post("users.php", {
          optn: "Genid",
          uid: this.userId,
          uname: this.userName,
        })
        .then((response) => {
          var resp = response.data;
          muid = resp.uid;
        });
      this.formData.uid = muid;
    },
  },
  mounted() {
    this.loading = true;
    this.formBlank();

    this.loading = false;
  },
};
</script>

<style>
@import "./css/master.css";
</style>
<style scoped>
.class-list {
  /* position: absolute;
  top: 10px;
  border-radius: 12px;
  left: 0;
  right: 0;
  box-shadow: 0px 1px 2px 1px lightgray;
  height: 260px;
  overflow-y: auto;
  width: 450px;
  background-color: white;
  z-index: 9999; */
  position: absolute;
  top: 10px;
  border-radius: 12px;
  left: 0;
  right: 0;
  box-shadow: 0px 1px 2px 1px lightgrey;
  height: 660px;
  overflow-y: auto;
  width: 450px;
  background-color: white;
  z-index: 9999;
}
.title-bar {
  position: sticky;
  top: 0px;
  display: block;
  padding: 4px;
}

.title-bar .sbtn {
  position: absolute;
  right: 6px;
}
.class-list ul {
  margin: 0px;
  /* padding: 15px; */
  text-align: left;
}
.class-list ul li {
  list-style-type: none;
  cursor: pointer;
  margin: 0px;
  padding: 0px;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
.class-list ul li label {
  display: inline-block;
  margin-left: 6px;
  cursor: pointer;
}

.bgdark {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100%;
  clear: both;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}
.class-list label {
  width: calc(100% - 50px);
}
</style>
