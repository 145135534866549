<template>
  <widget-box>
    <template #title> Messages Sent </template>
    <template #default>
      <div class="filters">
        As on Date:
        <input
          type="date"
          class="form-control dt"
          v-model="docdt"
          @change="getChartData()"
        />
      </div>
      <card-loader v-if="loading"></card-loader>
      <div class="wid_body" v-else>
        <bar-chart
          ref="MsgChart"
          :ChartData="MyData"
          :ChartLabel="MyLabel"
          :ChartId="'MessageChart'"
          :ChartColor="ChartColor"
          :ChartHead="ChartHead"
          :LegendDisplay="false"
        ></bar-chart>
      </div>
    </template>
  </widget-box>
</template>

<script>
import axios from "axios";
import WidgetBox from "./WidgetBox.vue";
import BarChart from "../Charts/BarChart.vue";
import MyFun from "@/js/MyFun";

export default {
  name: "MsgChart",
  components: {
    BarChart,
    WidgetBox,
  },
  data() {
    return {
      outletid: "",
      userId: this.$store.state.userId,
      userName: this.$store.state.userName,
      datalist: [],
      loading: false,
      MyLabel: [],
      MyData: [],
      ChartColor: ["#FFBF00", "#DE3163", "#E74C3C"],
      ChartHead: "Messages Sent",
      docdt: MyFun.getdtnow(),
    };
  },
  methods: {
    async getChartData() {
      this.loading = true;

      this.MyLabel = [];
      this.MyData = [];
      await axios
        .post("widgets.php", {
          optn: "MessageWid",
          docdt: this.docdt,
        })
        .then((response) => {
          if (response.data.Errorsts == false) {
            this.datalist = response.data.List;
            this.loading = false;
            for (var i = 0; i < response.data.List.length; i++) {
              this.MyLabel.push(response.data.List[i]["msgdes"]);
              this.MyData.push(parseFloat(response.data.List[i]["msgcount"]));
            }
          }
        });
      await this.$refs.MsgChart.refreshChart();
    },
  },
  async mounted() {
    await this.getChartData();
  },
};
</script>

<style src="./widget.css" scoped></style>
