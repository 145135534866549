<template>
  <label>Upload Files:</label><br />
  <input
    type="file"
    id="myfiles"
    multiple
    @change="showFiles"
    v-show="selectedfiles.length == 0"
  />
  <div class="file-container">
    <div
      class="selected-files"
      v-for="(file, index) in selectedfiles"
      :key="index"
    >
      <span class="file-name">{{ file.name }}</span>
      <template v-if="isImage(file.name)">
        <img :src="getFilePreview(file)" class="file-image" />
      </template>
      <template v-else>
        <img
          :src="getFileImage(checkExtension(file.name))"
          class="file-image"
        />
      </template>
      <span @click="removeFile(index)" class="remove-btn">Remove</span>
    </div>
  </div>
</template>
<script>
export default {
  emits: ["files-data"],
  data() {
    return {
      selectedfiles: [],
    };
  },
  methods: {
    showFiles(event) {
      this.selectedfiles = Array.from(event.target.files);

      this.refreshFiles();
    },
    removeFile(idx) {
      this.selectedfiles.splice(idx, 1);
      if (this.selectedfiles.length == 0) {
        this.selectedfiles = [];
        var myinput = document.getElementById("myfiles");
        myinput.value = "";
      }
      this.refreshFiles();
    },
    getFilePreview(file) {
      return URL.createObjectURL(file);
    },
    checkExtension(xfile) {
      var extension = xfile.substring(xfile.lastIndexOf("."), xfile.length);
      return extension.toLowerCase();
    },
    isImage(img) {
      var myimg = img.toLowerCase();
      var ext = this.checkExtension(myimg);
      if (
        ext == ".png" ||
        ext == ".jpg" ||
        ext == ".jpeg" ||
        ext == ".bmp" ||
        ext == ".heif"
      ) {
        return true;
      } else {
        return false;
      }
    },
    getFileImage(ext) {
      var img = "";
      switch (ext.toLowerCase()) {
        case ".ppt":
          img = "ppt.png";
          break;
        case ".pptx":
          img = "ppt.png";
          break;
        case ".doc":
          img = "doc.png";
          break;
        case ".docx":
          img = "doc.png";
          break;
        case ".xls":
          img = "xls.png";
          break;
        case ".xlsx":
          img = "xls.png";
          break;
        case ".pdf":
          img = "pdf.png";
          break;
        case ".mp4":
          img = "video.png";
          break;
        default:
          img = "file.png";
          break;
      }
      img = require(`@/assets/${img}`);
      return img;
    },
    refreshFiles() {
      this.$emit("files-data", this.selectedfiles);
    },
    clearFiles() {
      this.selectedfiles = [];
      var myinput = document.getElementById("myfiles");
      myinput.value = "";
    },
  },
};
</script>
<style scoped>
.file-container {
  position: relative;
  display: block;
}
.selected-files {
  position: relative;
  display: inline-block;
  padding: 8px;
  text-align: center;
  margin: 5px;
}
.file-name {
  position: relative;
  display: block;
  font-size: 10px;
}
.file-image {
  width: 80px;
  height: 80px;
}
.remove-btn {
  position: relative;
  display: block;
  font-size: 12px;
  color: red;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}
</style>
