<template>
  <div>
    <div class="read-list">
      <div class="title">
        Attachment
        <div class="close-btn" @click="hideBox">
          <span class="fas fa-times-circle"></span>
        </div>
      </div>
      <div class="student-list">
        <span class="class-detail" v-if="des != ''">Class:{{ des }}</span>
        <div class="attachments">
          <a
            v-for="file in attachlist"
            :key="file.trackno"
            :href="file.filepath + file.filename"
            class="attachment-item"
            target="_blank"
          >
            <img :src="file.fileicon" v-if="file.fileicon != ''" />
            <img :src="file.filepath + file.filename" v-else />
          </a>
        </div>
      </div>
    </div>
    <div class="bg-div"></div>
  </div>
</template>

<script>
export default {
  emits: ["hidebox"],
  data() {
    return {
      attachlist: [],
      des: "",
    };
  },
  methods: {
    async getReaddata(xrw) {
      this.des = xrw.des;
      this.attachlist = xrw.list;
    },
    hideBox() {
      this.$emit("hidebox");
    },
  },
};
</script>

<style scoped>
.read-list {
  position: fixed;
  border: 0px solid transparent;
  border-radius: 6px;
  width: 450px;
  height: 550px;
  overflow: auto;
  background-color: white;
  box-shadow: 0px 0px 8px 0px rgb(46, 46, 46);
  left: calc(50% - 350px);
  top: 80px;
  z-index: 9999;
}

.title {
  position: relative;
  width: 100%;
  padding: 6px;
  color: white;
  background-color: black;
}

.class-detail {
  position: relative;
  padding: 5px;
  font-weight: bold;
}

.attachments {
  position: relative;
  display: block;
  padding: 5px;
  text-align: center;
}

.attachment-item img {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  margin: 5px;
}
.bg-div {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100%;
  background-color: #00000067;
}

@media only screen and (max-width: 600px) {
  .read-list {
    width: calc(100% - 10px);
    left: 0px;
    right: 0px;
    margin: 0px auto !important;
  }
}
</style>
