<!-- @format -->

<template>
  <div class="login-div">
    <div class="inner-div">
      <img src="../assets/complogo.png" class="logo" alt="Logo" />
      <label class="tocenter"><u>Login</u></label>
      <span class="errmsg" :class="errclass" v-if="err">{{ errmsg }}</span>
      <label for="uname">User Name:</label>
      <input
        type="text"
        name="uname"
        ref="uname"
        class="form-control"
        v-model="uname"
        maxlength="20"
        autocomplete="off"
        autofocus
        v-on:keydown.enter="login()"
      />
      <label for="upass">Password:</label>
      <input
        type="password"
        name="upass"
        ref="upass"
        class="form-control"
        v-model="upass"
        maxlength="20"
        autocomplete="off"
        v-on:keydown.enter="login()"
      />
      <button class="btn btn-success" type="button" @click="login()">
        Login
      </button>
      <button class="btn btn-danger" type="button" @click="cancel()">
        Cancel
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "LoginForm",
  emits: ["login-btn"],
  data() {
    return {
      uname: "",
      upass: "",
      err: false,
      errmsg: "",
      errclass: "",
    };
  },
  methods: {
    async login() {
      this.uname = this.uname.toUpperCase();
      this.upass = this.upass.toUpperCase();
      if (this.uname != "" && this.upass != "") {
        this.err = true;
        this.errclass = "warnmsg";
        this.errmsg = "Authenticating...";
        var loginsts = {
          isAuth: false,
          Uname: this.uname,
          Uid: "",
          StoreFlg: "",
          DepId: "",
          DepDes: "",
        };

        var response;
        await axios
          .post("users.php", {
            optn: "checkuser",
            uname: this.uname,
            upass: this.upass,
          })
          .then(function (resp) {
            response = resp.data;
            console.log(resp);
          });

        if (response.Errorsts === false) {
          if (response.List.length > 0) {
            this.errclass = "donemsg";
            this.errmsg = "Welcome!" + this.uname;
            var control = response.control[0];
            loginsts.isAuth = true;
            loginsts.Uid = response.List[0].uid;
            loginsts.Uname = response.List[0].uname;
            localStorage.setItem("Uname", loginsts.Uname);
            localStorage.setItem("Uid", loginsts.Uid);
            localStorage.setItem("Auth", loginsts.isAuth);
            localStorage.setItem("school", control.school);
            this.$store.state.schoolName = control.school;

            if (response.List[0].catg == "Y") {
              this.$store.state.isTeacher = true;
              localStorage.setItem("IsTeacher", true);
              localStorage.setItem("ShowDash", false);
              this.$store.state.showDash = false;
              this.$store.state.userName = response.List[0].ename;
            } else {
              localStorage.setItem("ShowDash", true);
              this.$store.state.showDash = true;
              localStorage.setItem("IsTeacher", false);
              this.$store.state.isTeacher = false;
              this.$store.state.userName = loginsts.Uname;
            }

            this.$store.state.userId = loginsts.Uid;
            this.$router.push("/dashboard");
          } else {
            this.errclass = "dangermsg";
            this.errmsg = "Invalid Username/Password!";
          }
        } else {
          this.errclass = "dangermsg";
          this.errmsg = "Unable to Login! Something Went Wrong!";
          console.log(response.ErrorMsg);
        }
      } else {
        this.errmsg = "Please Fill User Name/Password!";
        this.errclass = "dangermsg";
      }
    },
    cancel() {
      this.uname = "";
      this.upass = "";
      this.$refs.uname.focus();
    },
  },
};
</script>

<style scoped>
.login-div {
  position: relative;
  margin: 0px auto !important;
  left: 0;
  right: 0;
  top: 50px;
  width: 350px;
  border-radius: 4px;
  padding: 8px;
  padding-top: 20px;
  padding-bottom: 40px;
  text-align: left;
  box-shadow: 0px 2px 4px 1px lightgray;
  background-color: white;
}

.inner-div {
  padding: 5px;
}

.logo {
  position: relative;
  display: block;
  margin: 0px auto !important;
  width: 80px;
}

label {
  position: relative;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 5px;
  display: block;
}

.btn {
  display: block;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}

.errmsg {
  position: relative;
  display: block;
  text-align: center;
  padding: 3px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
}
.dangermsg {
  background-color: #f12b08f2;
  color: white;
}
.warnmsg {
  background-color: #ebca12;
  color: white;
}
.donemsg {
  background-color: #05bb23;
  color: white;
}
</style>
