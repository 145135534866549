<template>
  <div class="base-card">
    <span class="card-icon" :class="CardIcon"></span>

    <span>{{ CardTitle }}</span>
  </div>
</template>

<script>
export default {
  props: ["CardTitle", "CardLink", "CardIcon"],
};
</script>
<style scoped>
.base-card {
  position: relative;
  top: 0px;
  display: inline-block;
  margin-left: 35px;
  width: 250px;
  font-size: 16px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 1px lightgray;
  text-align: center;
  padding: 35px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: all 0.5s ease-in-out;
  font-weight: bold;
}

.base-card:hover {
  background-color: rgb(223, 223, 223);
}

.card-icon {
  position: relative;
  margin-right: 8px;
  font-size: 20px;
}

@media only screen and (max-width: 600px) {
  .base-card {
    left: 0px;
    right: 0px;
    top: 25px;
    width: 90%;
    font-size: 18px;
    margin: 5px;
  }
}
</style>
