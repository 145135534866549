<template>
  <widget-box :ExtraClass="'big-widget'">
    <template #title>Absent/Present </template>
    <template #default>
      <div class="filters">
        As on Date:
        <input
          type="date"
          class="form-control dt"
          v-model="docdt"
          @change="getChartData()"
        />
      </div>
      <card-loader v-if="loading"></card-loader>
      <div class="wid_body" id="absentschart" v-else>
        <stacked-bar
          ref="AbChart"
          :ChartData="MyData"
          :ChartLabel="MyLabel"
          :ChartId="'AbsentChart'"
          :ChartColor="ChartColor"
          :ChartHead="ChartHead"
          :LegendDisplay="false"
          :ChartData1="MyData1"
          :ChartColor1="ChartColor1"
          :ChartHead1="ChartHead1"
          :ChartWidth="divwidth"
          :ChartHeight="divheight"
        ></stacked-bar>
      </div>
    </template>
  </widget-box>
</template>

<script>
import axios from "axios";
import WidgetBox from "./WidgetBox.vue";
import StackedBar from "../Charts/StackedBar.vue";
import MyFun from "@/js/MyFun";

export default {
  name: "AbChart",
  components: {
    StackedBar,
    WidgetBox,
  },
  data() {
    return {
      outletid: "",
      userId: this.$store.state.userId,
      userName: this.$store.state.userName,
      datalist: [],
      loading: false,
      MyLabel: [],
      MyData1: [],
      MyData: [],
      ChartColor: ["rgb(241, 22, 22)"],
      ChartColor1: ["#19e45c"],
      ChartHead: "Absent",
      ChartHead1: "Present",
      docdt: MyFun.getdtnow(),
      divwidth: 0,
      divheight: 0,
    };
  },
  methods: {
    async getChartData() {
      this.loading = true;
      this.MyLabel = [];
      this.MyData = [];
      this.MyData1 = [];
      await axios
        .post("widgets.php", {
          optn: "AbsentChart",
          docdt: this.docdt,
        })
        .then((response) => {
          if (response.data.Errorsts == false) {
            this.datalist = response.data.List;
            this.loading = false;
            for (var i = 0; i < response.data.List.length; i++) {
              this.MyLabel.push(response.data.List[i]["label"]);
              this.MyData.push(parseFloat(response.data.List[i]["absent"]));
              this.MyData1.push(parseFloat(response.data.List[i]["present"]));
            }
          }
        });
      // await this.$refs.AbChart.refreshChart();
    },
    getDim() {
      var myw = document.getElementById("absentschart").clientWidth;
      var myh = document.getElementById("absentschart").clientHeight;
      this.divwidth = myw;
      this.divheight = myh;
    },
  },
  async mounted() {
    await this.getDim();
    await this.getChartData();
  },
};
</script>

<style src="./widget.css" scoped></style>
