<template>
  <div class="widgetbox" :class="ExtraClass">
    <div class="widtitle">
      <slot name="title"></slot>
    </div>
    <div class="widbody">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ExtraClass: {
      type: String,
      default: "",
      required: false,
    },
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.widgetbox {
  position: relative;
  display: inline-block;
  top: 0px;
  margin: 15px;
  background-color: white;
  box-shadow: 0px 2px 4px 1px lightgray;
  padding: 12px;
  border-radius: 5px;
  word-break: keep-all;
  word-spacing: normal;
  height: 350px;
  width: 400px;
  padding-bottom: 20px;
}
.big-widget {
  height: 350px;
  width: calc(100vw - 15px) !important;
}
.widtitle {
  position: relative;
  display: block;
  text-align: center;
  font-weight: 700;
}
.widbody {
  position: relative;
  display: block;
  padding: 0px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 600px) {
  .widgetbox {
    min-width: calc(100% - 60px);
    min-height: calc(400px - 45px);
    width: 100%;
  }
}
</style>
