/** @format */

import LoginPage from "../views/LoginPage.vue";
import HomePage from "../views/HomePage.vue";
import DashboardPage from "../views/DashboardPage.vue";

import UserList from "../views/academics/UserList.vue";
import StudentUsers from "../views/academics/StudentUsers.vue";
import MessageList from "../views/academics/MessageList.vue";
import MessageAdd from "../views/academics/MessageAdd.vue";
import GalleryAdd from "../views/academics/GalleryAdd.vue";
import GalleryList from "../views/academics/GalleryList.vue";
import HomeworkList from "../views/academics/HomeworkList.vue";
import HomeworkAdd from "../views/academics/HomeworkAdd.vue";
import BlockStudents from "../views/academics/BlockStudents.vue"

const routes = [
  {
    path: "/",
    name: "Login",
    component: LoginPage,
    beforeRouteEnter(to, from, next) {
      console.log("to:" + to);
      console.log("from:" + from);
      console.log("next:" + next);
      // var Uid = localStorage.getItem("Uid");
      // // if(to.query.redirectFrom)
      // // {
      // //     next(vm => {
      // //         if (Uid == null) {
      // //         vm.errorMsg ="Sorry, you don't have the right access to reach the route requested"
      // //         }
      // //     })
      // // }
      // // else{
      // //   next()
      // // }
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: HomePage,
    children: [
      // Masters
      {
        path: "/dashboard",
        component: DashboardPage,
        beforeRouteEnter(to, from, next) {
          console.log("to:" + to);
          console.log("from:" + from);
          console.log("next:" + next);
        },
      },
      {
        path: "/users",
        component: UserList,
      },
      {
        path: "/studentusers",
        component: StudentUsers,
      },
      {
        path: "/messageadd",
        component: MessageAdd,
      },
      {
        path: "/messages",
        component: MessageList,
      },
      {
        path: "/galleryadd",
        component: GalleryAdd,
      },
      {
        path: "/gallery",
        component: GalleryList,
      },
      {
        path: "/homeworkadd",
        component: HomeworkAdd,
      },
      {
        path: "/homeworklist",
        component: HomeworkList,
      },
      {
        path: "/blockstudent",
        component: BlockStudents,
      },
    ],
  },
];

export default routes;
