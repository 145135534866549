<template>
  <div class="multi-selection-chip">
    <div v-for="(item, index) in selectedItems" :key="index" class="chip">
      {{ item.cldes + " " + item.sec }}
      <span class="close" @click="removeItem(index)">x</span>
    </div>
    <input
      type="text"
      class="select-box"
      v-model="searchTerm"
      @keydown.enter.prevent="addSelectedItem"
      @keydown.down.prevent="highlightNext"
      @keydown.up.prevent="highlightPrevious"
      @click="showSelection"
    />
    <div class="hidden-div" v-show="showOptions" @click="showSelection"></div>
    <ul class="options" v-show="showOptions">
      <li
        v-for="(option, index) in filteredOptions"
        :key="option.trackno"
        :class="{ active: index === highlightedIndex }"
        @click="addSelectedItem"
        @mouseover="highlightedIndex = index"
      >
        {{ option.cldes + " " + option.sec }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["mydata"],
  emits: ["updated-classes"],
  data() {
    return {
      searchTerm: "",
      selectedItems: [],
      selectedOption: "",
      highlightedIndex: -1,
      options: [],
      showitems: false,
    };
  },
  methods: {
    // addItem() {
    //   if (
    //     this.selectedOption &&
    //     !this.selectedItems.includes(this.selectedOption)
    //   ) {
    //     this.selectedItems.push(this.selectedOption);
    //     this.selectedOption = "";
    //   }
    // },
    addSelectedItem() {
      if (this.highlightedIndex !== -1) {
        try {
          const selectedItem = this.filteredOptions[this.highlightedIndex];
          if (selectedItem.trackno == 0) {
            this.selectedItems = this.options;
          } else {
            if (!this.selectedItems.includes(selectedItem)) {
              this.selectedItems.push(selectedItem);
            }
          }
        } catch (error) {
          const selectedItem = this.filteredOptions[0];

          if (selectedItem.trackno == 0) {
            this.selectedItems = this.options;
          } else {
            if (!this.selectedItems.includes(selectedItem)) {
              this.selectedItems.push(selectedItem);
            }
          }
        }
        // this.showitems = false;
        this.highlightedIndex = -1;
        this.refreshItems();
      }
    },
    showSelection() {
      this.showitems = !this.showitems;
      // this.searchTerm = "";
      this.highlightedIndex = 0;
    },
    removeItem(index) {
      if (index >= 0) {
        var myobj = this.selectedItems[index];
        if (myobj.trackno == 0) {
          this.selectedItems = [];
        } else {
          this.selectedItems.splice(index, 1);
        }
        this.refreshItems();
      }
    },
    genItems() {
      this.options = [];
      this.options = this.mydata;
    },
    highlightNext() {
      if (this.highlightedIndex < this.filteredOptions.length - 1) {
        this.highlightedIndex++;
      }
    },
    highlightPrevious() {
      if (this.highlightedIndex > 0) {
        this.highlightedIndex--;
      }
    },
    refreshItems() {
      this.$emit("updated-classes", this.selectedItems);
    },
  },
  computed: {
    filteredOptions() {
      // const regex = new RegExp(this.searchTerm, "i");
      return this.options.filter((rw) => {
        var mystr = rw.cldes.trim() + rw.sec.trim();
        if (mystr.toUpperCase().includes(this.searchTerm.toUpperCase())) {
          // Not In Selection
          if (
            !this.selectedItems.some((mydata) => mydata.trackno == rw.trackno)
          ) {
            return rw;
          }
        }
      });
      // } else {
      //   return this.options;
      // }
    },
    showOptions() {
      return this.showitems == true && this.filteredOptions.length > 0;
    },
  },
  async mounted() {
    await this.genItems();
  },
};
</script>
<style scoped>
.multi-selection-chip {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 5px;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-top: 5px;
}

.chip {
  background-color: #e0e0e0;
  border-radius: 16px;
  padding: 3px 6px;
  margin-right: 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
}

.chip .close {
  cursor: pointer;
  margin-left: 8px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 4px;
  padding-right: 4px;
  border: 2px solid gray;
  border-radius: 25px;
}

.chip .close:hover {
  background-color: red;
  color: white;
  border: 2px solid red;
}

.multi-selection-chip select {
  position: relative;
  display: block;
  width: calc(100% - 10px);
  border: 0px;
  outline: none;
  padding: 5px;
}

.multi-selection-chip .select-box {
  position: relative;
  display: block;
  outline: none;
  width: calc(100% - 10px);
  border: none;
}
.options {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
}

.options li {
  padding: 5px;
  cursor: pointer;
  display: block;
}

.options li.active {
  background-color: #f1f1f1;
}
.hidden-div {
  position: absolute;
  top: 100%;
  /* background-color: #00000069; */
  background-color: transparent;
  left: 0px;
  width: 100%;
  height: 60vh;
}
</style>
