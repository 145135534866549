<!-- @format -->

<template>
  <div class="trackdiv">
    <div class="title-bar">
      <div class="title-text">Select Student</div>
      <div class="close-btn" @click="closeIt">
        <span class="fas fa-times-circle"></span>
      </div>
    </div>
    <div class="contents">
      <div class="filter">
        <input
          type="text"
          placeholder="Search"
          v-model="search"
          class="form-control"
          @keyup="getList()"
          ref="searchbox"
        />
      </div>
      <div class="table-area">
        <card-loader v-if="loading"></card-loader>
        <table class="table" v-else>
          <thead>
            <tr>
              <th>ID</th>
              <th>Student</th>
              <th>Father</th>
              <th>Class</th>
              <th>Sec</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in tabledata" :key="row.glc" @click="selectRow(row)">
              <td>{{ row.glc }}</td>
              <td>{{ row.gln }}</td>
              <td>{{ row.fname }}</td>
              <td>{{ row.cldes }}</td>
              <td>{{ row.sec }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="bgdark"></div>
</template>
<script>
import axios from "axios";
export default {
  emits: ["on-select", "on-close"],
  data() {
    return {
      search: "",
      loading: false,
      tabledata: [],
      userId: this.$store.state.userId,
      userName: this.$store.state.userName,
    };
  },
  methods: {
    async getList() {
      this.loading = true;
      await axios
        .post("glmast.php", {
          optn: "List",
          msearch: this.search,
          uid: this.userId,
          uname: this.userName,
          isteacher: this.$store.state.isTeacher,
          maxrec: 100,
        })
        .then((response) => {
          var resp = response.data;
          if (resp.Errorsts == false) {
            this.tabledata = resp.List;
          } else {
            console.log("Error:" + resp.ErrorMsg);
          }
        });
      this.loading = false;
    },
    setFocus() {
      setTimeout(() => {
        this.$refs.searchbox.focus();
      }, 400);
    },
    selectRow(xobj) {
      this.$emit("on-select", xobj);
    },
    closeIt() {
      this.$emit("on-close");
    },
  },
  mounted() {
    this.getList();
    this.setFocus();
  },
};
</script>

<style src="./css/track.css">
/* @import './assets/styles/yourstyles.css'; */
</style>
