<!-- @format -->

<template>
  <top-bar v-show="isLogin" @toggleBar="toggleBar"> </top-bar>
  <div class="main-div" id="#maindiv">
    <!-- <side-bar ref="sidebar"></side-bar> -->
    <div class="right-col">
      <router-view />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TopBar from "@/components/TopBar.vue";
// import SideBar from "@/components/SideBar.vue";
export default {
  name: "HomePage",
  components: {
    TopBar,
    // SideBar,
  },
  data() {
    return {
      isLogin: true,
      StoreUser: this.$store.state.storeFlg,
    };
  },
  methods: {
    // login(xresp) {
    //   console.log(xresp);
    //   if (xresp.isAuth == true) {
    //     localStorage.setItem("Uname", xresp.Uname);
    //     localStorage.setItem("Auth", xresp.isAuth);
    //     this.isLogin = xresp.isAuth;
    //   }
    // },
    toggleBar() {
      if (this.$refs.sidebar.showSidebar == "") {
        this.$refs.sidebar.showSidebar = "show-sidebar";
      } else {
        this.$refs.sidebar.showSidebar = "";
      }
    },
  },
  activated() {},
  mounted() {
    if (this.$store.state.isAuth != null) {
      // this.isLogin = this.$store.state.isAuth;
    } else {
      // this.isLogin = false;
    }
  },
};
</script>
<style scoped>
.main-div {
  position: relative;
  padding: 0px;
  margin: 0px;
  top: 0px;
  max-height: 100vh;
  text-align: left;
  justify-content: left;
}

.left-col {
  float: left;
  top: 0px;
  left: 0px;
  /* width: 220px; */
  width: 10%;
  height: calc(100vh - 57px);
}

.right-col {
  top: 65px;
  position: relative;
  float: left;
  left: 0px;
  /* width: calc(100% - 255px); */
  width: calc(100% - 15px);
  height: calc(100vh - 75px);
}

@media only screen and (max-width: 1000px) {
  .left-col {
    display: none;
  }
  .right-col {
    /* width: calc(100% - 40px);
    left: 40px; */
    width: 100%;
  }
}
</style>
